
export const environment = {
  stage: 'dev',
  production: false,
  landing:true,
  apiUrl: 'http://localhost:4444',
  authUrl: '',
  g4key:'',
  ga:'',
  paypal: 'ARiuhnf4aAoV6AkZdFWgrB-eYjmmtx6q3676BfQd3bleEFRD2Gy81ian2EOwmDleeOYtMsThV_FK3M6P',
  authMode: 'firebase',
  captchaKey: "6Lcu7-IUAAAAABi3_U37419wdjG1NP_DsFObL9l2",
  version: '1.0.2',
  language: "fr",
  domain:"dev.adnskis.com",
  defaultSpinnerTimeout: 60,
  brevoApiKey: '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',
  brevoNewsletterList: 2,
  brevoVpList: 6,
  brevoContactPurchaseList: 2,
  brevoOrderTemplate: 3,
  vpKey: 'ho'
};
