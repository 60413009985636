

import { AfterViewInit, Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Ski } from '@models/skis';

@Component({
    selector: 'adn-dimentions',
    templateUrl: './dimentions.component.html',
    styleUrls: ['./dimentions.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DimentionsComponent implements OnInit  {
    @Input() ski: Ski;

    constructor(

    ) {

    }

    ngOnInit() {}


    ionViewDidEnter() {
        
    }

    
}
