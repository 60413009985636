

import { Injectable } from "@angular/core";
import { ToastController  } from '@ionic/angular';

@Injectable()
export class NotificationService {
  private snackBarAutoHide: number = 5000; //milliseconds for notification , 5 secs
  private position:any = 'top';

  constructor(private alert: ToastController ) {}

  async message(message) {
    let toast = await this.alert.create({
      message: message, 
      duration: this.snackBarAutoHide,
      position: this.position,
      cssClass: ['message'],
      buttons: [
        {
          text: 'x',
          role: 'cancel'
        }
      ]
    });
    toast.present();
  }

  async success(message) {
    let toast = await this.alert.create({
      message: message, 
      duration: this.snackBarAutoHide,
      position: this.position,
      cssClass: ['message-success'],
      buttons: [
        {
          text: 'x',
          role: 'cancel'
        }
      ]
    });
    toast.present();
  }

  async warning(message) {
    let toast = await this.alert.create({
      message: message, 
      duration: this.snackBarAutoHide,
      position: this.position,
      cssClass: ['message-warning'],
      buttons: [
        {
          text: 'x',
          role: 'cancel'
        }
      ]
    });
    toast.present();
  }

  async error(message) {
    console.log('Notify error: ',message);
    let toast = await this.alert.create({
      message: message, 
      duration: this.snackBarAutoHide,
      position: this.position,
      cssClass: ['message-error'],
      buttons: [
        {
          text: 'x',
          role: 'cancel'
        }
      ]
    });
    toast.present();
  }
}