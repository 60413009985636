import { environment } from "@envs/environment";

export const CONTACT_API = 'https://api.brevo.com/v3/contacts';
export const CONTACT_LIST_API = 'https://api.brevo.com/v3/contacts/lists/listId/contacts/add'

export const SMTP_EMAIL = 'https://api.brevo.com/v3/smtp/email';

export const VP_LIST = environment.brevoVpList;

export const NEWSLETTER_LIST = environment.brevoNewsletterList;
export const CONTACT_PURCHASE_LIST = environment.brevoContactPurchaseList;

export const ORDER_TEMPLATE = environment.brevoOrderTemplate;
export const TEST_MAIL_TEMPLATE = 2;
