export function onlyLettersAndNumbers(str) {
    return /^[A-Za-z0-9]*$/.test(str);
}


export function extractCodeFromString(inputString: string): string[] {
    const regex = /\`\`\`([^\`]+)\`\`\`/gm;
    const matches: string[] = [];
    let match: RegExpExecArray | null;
    while ((match = regex.exec(inputString)) !== null) {
      if (match.length >= 2) {
        matches.push(match[1]);
      }
    }
    return matches;
  }
  