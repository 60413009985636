import { Injectable } from "@angular/core";
import { filter } from "rxjs/operators";
import { NavigationEnd, Router, ActivatedRoute } from "@angular/router";
//import { Platform } from '@ionic/angular';

import { DeviceDetectorService } from "ngx-device-detector";
import { HttpService } from "@alphaa/services/http.service";
import { NgxSpinnerService } from "ngx-spinner";
import { CoreService } from "../core.service";
import { LoggerService } from "../logger.service";
import { NotificationService } from "./notification.service";
import { NavController, PopoverController } from "@ionic/angular";
import { environment } from "@envs/environment";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class ICoreService extends CoreService {
  menuMode: string = "";

  private previousUrl: string;
  private currentUrl: string;

  public captchaKey = environment.captchaKey;
  public ga = environment.ga;

  constructor(
    public log: LoggerService,
    public http: HttpService,
    public router: Router,
    //public platform: Platform,
    public route: ActivatedRoute,
    public device: DeviceDetectorService,
    public spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    public notification: NotificationService,
    public popover: PopoverController,
    private navCtrl: NavController,
    public translate: TranslateService
  ) {
    super(log, http, router, route, device, spinner);

    this.currentUrl = this.router.url;
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Insérez votre logique ou vos actions ici en fonction de l'URL actuelle
        if (event.url.indexOf("/chat") !== -1) {
          this.menuMode = "chat";
        } else {
          this.menuMode = "";
        }
        // Ajoutez d'autres conditions en fonction de vos besoins
      }
    });
  }

  showSpinner(timeout: number = 3000) {
    this.spinner.show();
    setTimeout(() => this.spinner.hide(), timeout);
  }

  hideSpinner() {
    this.spinner.hide();
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  goBack() {
    console.log("goBack", this.getPreviousUrl());
    if (this.getPreviousUrl()) {
      this.navCtrl.back();
    } else {
      this.router.navigate(["/"]);
    }
  }

  public goTo(url: string, parameters: {}) {
    const queryParams = Object.assign({}, parameters);

    // Obtenir l'URL actuelle sans les paramètres
    const currentUrlWithoutParams = this.router.url.split("?")[0];

    // Naviguer vers la nouvelle page
    this.router
      .navigate([url], {
        queryParams: queryParams,
        queryParamsHandling: "merge",
      })
      .then(() => {
        if (currentUrlWithoutParams === url) {
          // Vous êtes déjà sur la page, aucune action nécessaire
          //window.location.reload();
        }
      });

    const savedVersion = localStorage.getItem("site_version");
    const version = "" + environment.version;
    if (savedVersion !== version) {
      localStorage.setItem("site_version", version);
      if (
        true //confirm("Une nouvelle version est disponible. Voulez-vous la charger ?")
      ) {
        console.log("version reload, from ", savedVersion, " to ", version);
        window.location.reload(); // Forcer le rechargement en ignorant le cache
        localStorage.setItem("site_version", version);
      }
    }
  }

  public toggleMenu() {
    const splitPane = document.querySelector("ion-split-pane");
    const windowWidth = window.innerWidth;
    const splitPaneShownAt = 992;
    const when = `(min-width: ${splitPaneShownAt}px)`;

    const menu = splitPane.querySelector("ion-menu");

    if (windowWidth >= splitPaneShownAt) {
      // split pane view is visible
      const open = splitPane.when === when;
      splitPane.when = open ? false : when;
    } else {
      // split pane view is not visible
      // toggle menu open

      return menu.open();
    }
  }

  public getActiveRoute() {
    this.activatedRoute.url.subscribe(() => {
      let activePage = this.activatedRoute.snapshot["_routerState"].url;
      console.log("Page active : " + activePage);
      return activePage;
    });
  }

  navigateToSection(sectionId: string) {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  
}
