import { User } from "@alphaa/models/user.model";

import { environment } from "@envs/environment";

import { DynClass } from "@alphaa/models/dyn.model";

export class BaseView extends DynClass {
  public currentUser: User;
  public isLanding = environment.landing;
  public isProd = environment.production;
  public isLocal = location.hostname == "localhost";
  public initiated = false;
  public timeout: any = null;
  public isAdmin = false;

  constructor() {
    super();
    this.isAdmin = this.saved("isAdmin", false);
  }

  goToUrl(url: string) {
    window.open(url, "_blank");
  }

  switchAdmin() {
    this.isAdmin = !this.isAdmin;
    this.save("isAdmin", this.isAdmin);
    window.location.reload();
  }

  scrollTo(id: string) {
    const element = document.getElementById(id);
    console.log(element);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  scrollToTop() {
    //this.router.navigate(['item'], {queryParams:{uuid:ski.uuid}});
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
}
