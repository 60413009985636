
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./pages/cart/cart.module').then( m => m.CartPageModule)
  },
  {
    path: 'item',
    loadChildren: () => import('./pages/item/item.module').then( m => m.ItemPageModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./pages/products/products.module').then( m => m.ProductsPageModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/story/story.module').then( m => m.StoryPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'vp',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
    {
    path: 'review',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'delivery',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'sizes',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/cgu/cgu.module').then( m => m.CguPageModule)
  },
  {
    path: 'legal',
    loadChildren: () => import('./pages/cgu/cgu.module').then( m => m.CguPageModule)
  },
  {
    path: 'use',
    loadChildren: () => import('./pages/cgu/cgu.module').then( m => m.CguPageModule)
  },
  {
    path: 'connect',
    loadChildren: () => import('./pages/connect/connect.module').then( m => m.ConnectPageModule)
  },
  {
    path: 'collection',
    loadChildren: () => import('./pages/collection/collection.module').then( m => m.CollectionPageModule)
  },
  {
    path: 'values',
    loadChildren: () => import('./pages/adn/adn.module').then( m => m.ADNPageModule)
  },
  {
    path: 'history',
    loadChildren: () => import('./pages/adn/adn.module').then( m => m.ADNPageModule)
  },
  {
    path: 'team',
    loadChildren: () => import('./pages/adn/adn.module').then( m => m.ADNPageModule)
  },
  {
    path: 'ambassadors',
    loadChildren: () => import('./pages/adn/adn.module').then( m => m.ADNPageModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('./pages/adn/adn.module').then( m => m.ADNPageModule)
  },
  {
    path: 'ecodesign',
    loadChildren: () => import('./pages/adn/adn.module').then( m => m.ADNPageModule)
  },
  {
    path: 'materials',
    loadChildren: () => import('./pages/adn/adn.module').then( m => m.ADNPageModule)
  },
  {
    path: 'recycling',
    loadChildren: () => import('./pages/adn/adn.module').then( m => m.ADNPageModule)
  },
  {
    path: 'ingredientbrand',
    loadChildren: () => import('./pages/adn/adn.module').then( m => m.ADNPageModule)
  },
  {
    path: 'approach',
    loadChildren: () => import('./pages/adn/adn.module').then( m => m.ADNPageModule)
  },
  {
    path: 'identity',
    loadChildren: () => import('./pages/adn/adn.module').then( m => m.ADNPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./pages/payment/payment.module').then( m => m.PaymentPageModule)
  },
  {
    path: 'cgu',
    loadChildren: () => import('./pages/cgu/cgu.module').then( m => m.CguPageModule)
  },
  {
    path: 'page-not-found',
    loadChildren: () => import('./pages/page-not-found/page-not-found.module').then( m => m.PageNotFoundPageModule)
  },
  {
    path: 'add-address',
    loadChildren: () => import('./pages/add-address/add-address.module').then( m => m.AddAddressPageModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./pages/categories/categories.module').then( m => m.CategoriesPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/add-address/add-address.module').then( m => m.AddAddressPageModule)
    
  },
  {
    path: 'profil',
    loadChildren: () => import('./pages/profil/profil.module').then( m => m.ProfilPageModule)
    
  },
  
  {
    path: '**',
    redirectTo: 'page-not-found'
  }
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {useHash: true, preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
