import { Injectable, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { IonContent } from "@ionic/angular";

import { BaseView } from "@alphaa/models/views.model";
import { ICoreService } from "@alphaa/services/ionic/icore.service";

@Injectable({
  providedIn: "root",
})
export class BaseViewI extends BaseView {
  @ViewChild(IonContent) content: IonContent;
  public isMobile = false;

  constructor(protected core: ICoreService) {
    super();
    this.isMobile = core.device.isMobile();
  }

  goBack() {
    this.core.goBack();
  }

  public goTo(url: string, parameters: {}) {
    const queryParams = Object.assign({}, parameters);
    this.core.router.navigate([url], { queryParams: queryParams });
  }

  goToId(sectionId: string) {
    this.core.navigateToSection(sectionId);
  }

}
