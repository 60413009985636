
import { ICoreService } from '@alphaa/services/ionic/icore.service';
import { Component } from '@angular/core';

import { environment } from '@envs/environment';
import { UtilService } from '@services/util.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  environment=environment;

  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor(protected core: ICoreService, public util: UtilService) {
    //this.util.initLanguage();
  }
}
