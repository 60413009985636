// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
#rating {
  min-width: 80px;
  display: block;
  cursor: pointer;
}

.average-rating {
  position: relative;
  -webkit-appearance: none;
          appearance: none;
  color: transparent;
  width: auto;
  display: inline-block;
  vertical-align: baseline;
}

.average-rating::before {
  content: "★★★★★";
  position: absolute;
  top: 0;
  left: 0;
  color: rgba(0, 0, 0, 0.2);
  background: linear-gradient(90deg, gold var(--percent), rgba(0, 0, 0, 0.2) var(--percent));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/app/components/rating/rating.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACI,eAAA;EACA,cAAA;EACA,eAAA;AACJ;;AAEA;EACE,kBAAA;EACA,wBAAA;UAAA,gBAAA;EACA,kBAAA;EACA,WAAA;EACA,qBAAA;EACA,wBAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,yBAAA;EACA,0FACE;EACF,6BAAA;EACA,oCAAA;AAAF","sourcesContent":["// RAING\n#rating {\n    min-width:80px;\n    display:block;\n    cursor: pointer;\n}\n\n.average-rating {\n  position: relative;\n  appearance: none;\n  color: transparent;\n  width: auto;\n  display: inline-block;\n  vertical-align: baseline;\n}\n\n.average-rating::before {\n  content: '★★★★★';\n  position: absolute;\n  top: 0;\n  left: 0;\n  color: rgba(0,0,0,0.2);\n  background:\n    linear-gradient(90deg, gold var(--percent), rgba(0,0,0,0.2) var(--percent));\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
