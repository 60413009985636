
import { Component, Input, OnInit } from '@angular/core';

import { UtilService } from 'app/services/util.service';

import { ICoreService } from "@alphaa/services/ionic/icore.service";
import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';

import { BaseViewI } from '@alphaa/models/ionic/baseViewI.model';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailService } from '@services/mail.service';
import { ApiService } from '@services/api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ADNView } from '@models/view';
import { catchError, delayWhen, forkJoin, of, retryWhen, scan, switchMap, throwError, timer } from 'rxjs';
import { VP_LIST } from '@constants/brevo';

function retryWithDelay(maxRetries, delay) {
  return retryWhen(errors =>
    errors.pipe(
      scan((retryCount, error) => {
        if (retryCount >= maxRetries) {
          throw error;
        }
        return retryCount + 1;
      }, 0),
      delayWhen(() => timer(delay))
    )
  );
}

@Component({
  selector: 'modal-promo',
  templateUrl: './promo.modal.html',
  styleUrls: ['./promo.modal.scss'],
})
export class PromoModal extends ADNView implements OnInit {
  @Input() short: boolean = false;

  popKeyName = 'pop_12';
  load:boolean = false;

  message = '';
  public vpForm  = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    acceptNews: [true],
    recaptcha: ['', this.isProd ? []: []]
  });

  constructor(public core: ICoreService, public util: UtilService, protected route: ActivatedRoute, private formBuilder: FormBuilder, private apiService: ApiService, protected router: Router, private modalCtrl: ModalController, public emailService: EmailService) {
    super(core, route, router, util);
  }

  ngOnInit() {

  }

  closePop(save:boolean=true) {
    if (save) localStorage.setItem(this.popKeyName,'no');
    this.modalCtrl.dismiss();
  }

  submitForm() {
    this.core.showSpinner();
    this.load = true;

    const contact = this.vpForm.get('email').value;

    // Premier appel API pour enregistrer l'email
    const saveEmail$ = this.apiService.saveEmailVp(contact).pipe(
      retryWithDelay(1, 1000), // Réessayer 1 fois avec un délai de 1 seconde
      catchError(error => {
        console.error('Erreur lors de l\'enregistrement du mail', error);
        return of('error'); // Retourne une chaîne spécifique pour les erreurs
      })
    );

    const addContact$ = this.emailService.addContact(contact, [VP_LIST], {}).pipe(
      catchError(error => {
        // Log pour un utilisateur dupliqué mais non bloquant
        if (error.status === 400 && (error.error.code === 'duplicate_parameter' || error.error.message.indexOf('already') !== -1)) {
          console.error('Duplicate user');
          // Indique un cas spécial d'utilisateur dupliqué
          return of('duplicate');
        } else {
          console.error('Erreur lors de l\'ajout du contact', error);
          return of('error'); // Gestion des erreurs en retournant une chaîne spécifique
        }
      })
    );

    // Enchaînement pour ajouter le contact à la liste après l'avoir ajouté
    const addContactList$ = addContact$.pipe(
      switchMap(result => {
        // Procéder uniquement si le résultat précédent n'est pas une erreur
        if (result !== 'error') {
          return this.emailService.addContactList(contact, VP_LIST).pipe(
            retryWithDelay(1, 1000), // Réessayer une fois avec un délai de 1 seconde
            catchError(error => {

              if (error.status === 400 && (error.error.code === 'duplicate_parameter' || error.error.message.indexOf('already') !== -1)) {
                console.error('Duplicate user');
                // Indique un cas spécial d'utilisateur dupliqué
                return of('duplicate');
              } else {
                console.error('Erreur lors de l\'ajout à la liste VP', error);
                return of('error'); // Gestion des erreurs en retournant une chaîne spécifique
              }
            })
          );
        } else {
          return of(result);
        }
      })
    );

    // Exécution et gestion des deux appels API
    forkJoin({
      saveEmailResult: saveEmail$,
      addContactListResult: addContactList$
    }).subscribe(({ saveEmailResult, addContactListResult }) => {
      // Si au moins un appel réussit ou s'il y a eu un utilisateur dupliqué
      console.log('back',saveEmailResult, 'list', addContactListResult)
      if (saveEmailResult !== 'error' || addContactListResult !== 'error') {
        this.message = "Inscription validée";
      } else {
        // Si les deux appels retournent 'error'
        this.message = "Problème lors de l'inscription";
      }
      
      this.core.hideSpinner();
      this.load = false;
    });
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
