import { Component, ViewChild, ViewEncapsulation, ChangeDetectorRef, Input, OnInit, Renderer2, ElementRef, EventEmitter, Output, AfterViewInit, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CartModal } from '@components/modals/cart/cart.modal';
import { ModalController, NavController } from '@ionic/angular';

import { UtilService } from '@services/util.service';

import { BaseViewI } from '@alphaa/models/ionic/baseViewI.model';

import { ICoreService } from '@alphaa/services/ionic/icore.service';
import { AuthenticationService } from '@alphaa/services/authentication.service';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'adn-hmenu',
    templateUrl: './hmenu.component.html',
    styleUrls: ['./hmenu.component.scss'],
    encapsulation: ViewEncapsulation.None
  })
export class HMenuComponent extends BaseViewI  implements OnInit, AfterViewInit {
  @ViewChild('titleElement', { static: true, read: ElementRef }) titleElement: ElementRef;
  @Output() goToParent = new EventEmitter<void>();

  @Input() transparent: boolean = false;
  @Input() mode: string = 'light';
  @Input() isLastSlide: boolean = false;
  @Input() page: boolean = false;

  position:number;

  hm: number;

  activeMenu: boolean = false;
  menuBgPositions: { translateY: string; opacity: number }[] = [0, 1, 2, 3, 4].map(e => ({
    translateY: 'translateY(0%)',
    opacity: 0
  }));
  
  constructor(protected core: ICoreService, public util: UtilService, 
    private modalCtrl: ModalController, private router: Router, public auth:AuthenticationService) { 
    super(core);    
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.setMenu();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.setMenu();
    });
  }

  setMenu() {
    //const menu = document.querySelector('#header');
    const menusH = document.querySelectorAll('.header');
    if (menusH.length === 0) return; // Sortie anticipée si aucun menu trouvé.

    let timeoutId;

    menusH.forEach((menu) => {
      menu?.addEventListener('mouseenter', () => {
        clearTimeout(timeoutId);
        this.activeMenu = true;
      });

      menu?.addEventListener('mouseleave', (event: MouseEvent) => {
        const relatedTarget = event.relatedTarget as Node;

        if (!relatedTarget || !(relatedTarget instanceof Element) || (!relatedTarget.classList.contains('sub-menu') && !menu.contains(relatedTarget))) {
          timeoutId = setTimeout(() => {
            this.activeMenu = false;
          }, 200); 
        }
      });
    });

    const sousMenus = document.querySelectorAll('.sub-menu');

    sousMenus.forEach((sousMenu) => {
      sousMenu.addEventListener('mouseenter', () => {
        clearTimeout(timeoutId); 
        sousMenu.classList.add('actif');
      });

      sousMenu.addEventListener('mouseleave', (event: MouseEvent) => {
        const relatedTarget = event.relatedTarget as Node; // On garde le cast en Node
        // Même vérification pour le sous-menu
        if (!relatedTarget || !(relatedTarget instanceof Element) || (!relatedTarget.classList.contains('header') && !sousMenu.contains(relatedTarget))) {
          timeoutId = setTimeout(() => {
            //menu.classList.remove('actif');
            sousMenu.classList.remove('actif');
          }, 200);
        }
      });
    });

    const menus = document.querySelectorAll('.menu');

    menus.forEach((m) => {
      const menuLinks = m.querySelectorAll('.menu-link');
      let activeZIndex = 100;
  
      m.addEventListener('mouseenter', () => {
        menuLinks.forEach((link, index) => {
          if (link instanceof HTMLElement) {
            this.menuBgPositions[index].translateY = `translateY(${index * 100}%)`;
            this.menuBgPositions[index].opacity = 1;

            const translateYValue = (index + 1) * 100; // Calcule la translation en Y.
            link.style.transform = `translateY(${translateYValue}%)`;
            link.classList.add('visible'); // Rend le lien visible.
            link.style.zIndex = `${activeZIndex + index}`; // Augmente le z-index en fonction de l'index pour empiler correctement.
          }
        });
      });
  
      m.addEventListener('mouseleave', () => {
        menuLinks.forEach((link, index) => {
          if (link instanceof HTMLElement) {
            link.style.transform = `translateY(0%)`; // Réinitialise la position.
            link.classList.remove('visible'); // Rend le lien invisible.
            link.style.zIndex = ''; // Réinitialise le z-index pour le remettre à sa valeur par défaut.

            this.menuBgPositions[index].translateY = `translateY(0%)`;
            this.menuBgPositions[index].opacity = 0;
          }
        });
      });
    });
  }

  
  goTo(url: string, parameters: {}) {
    this.goToParent.emit();
    this.core.goTo(url, parameters);
  }

  toCart() {
    this.util.cartMode = 'cart';
    this.goTo('cart', {});
  }

  getButtonColor() {
    if(this.util.whiteTitle) return 'light';
    if(this.util.transp) return 'dark';
    if(this.mode=='dark') {
      return this.util.isMenuVisible()?'light':'dark';
    }
    return this.util.isMenuVisible()?'dark':'dark';
  }

  isActive(name:string,index:number) {
    const active = this.core.router.url.indexOf(name) != -1 || this.hm == index;
    return active;
  }

  async openCart() {
    const modal = await this.modalCtrl.create({
      component: CartModal,
      componentProps: {}
    });
    modal.present();
  }
}