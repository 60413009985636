import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoggerService {
  public info(...args: any[]) { Logger.info(...args); }

  public log(...args: any[]) { Logger.info(...args); }

  public debug(...args: any[]) { Logger.debug(...args); }

  public error(...args: any[]) { Logger.error(...args); }
}

export abstract class Logger {
  public static logs: Array<string> = [];

  public static level: number= 2;  //debug:2, info:1, error:0

  public static debug(...args: any[]) {
    Logger.trace(args);
    if(Logger.level >= 2) console.log(...args);
  }

  public static error(...args: any[]){
    Logger.trace(args);
    if(Logger.level >= 0)console.error(...args);
  }

  public static info(...args: any[]){
    Logger.trace(args);
    if(Logger.level >= 1) console.info(...args);
  }

  public static trace(...args: any[]) {
    Logger.logs.push(args.join(';'));
  }
}