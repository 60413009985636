import { BehaviorSubject, Observable, tap } from 'rxjs';

export class RequestTracker {
    private static value = 0;

    private static valueSubject = new BehaviorSubject<number>(RequestTracker.value);

    static Track(): MethodDecorator {
        return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
            const originalMethod = descriptor.value;

            descriptor.value = function (...args: any[]) {
                let silent = false;
                switch (originalMethod.name) {
                    case 'get': {
                        silent = args[2];
                        break;
                    }
                    case 'post': {
                        silent = args[4];
                        break;
                    }
                    case 'put':
                    case 'patch':
                    case 'delete': {
                        silent = args[3];
                        break;
                    }
                    default: {
                        throw new Error(`The method ${propertyKey} must be 'get', 'post', 'patch', 'put' or 'delete'`);
                    }
                }

                silent = silent ?? false;

                const results = originalMethod.apply(this, args);

                if (!(results instanceof Observable)) {
                    throw new Error(`The method ${propertyKey} must return an instance of Observable to be tracked`);
                }

                if (!silent) {
                    RequestTracker.increment();
                }

                return results.pipe(
                    tap({
                        complete: () => {
                            if (!silent) {
                                RequestTracker.decrement();
                            }
                        },
                        error: () => {
                            if (!silent) {
                                RequestTracker.decrement();
                            }
                        },
                    }),
                );
            };

            return descriptor;
        };
    }

    private static increment() {
        this.value++;
        this.valueSubject.next(this.value);
    }

    private static decrement() {
        this.value--;
        this.valueSubject.next(this.value);
    }

    public static getValue(): Observable<number> {
        return this.valueSubject.asObservable();
    }
}
