
import { Component, OnInit } from '@angular/core';
import { SKIS } from '@models/skis';
import { UtilService } from 'app/services/util.service';
import { IPayPalConfig, ICreateOrderRequest, IClientAuthorizeCallbackData, IOnApproveCallbackData } from 'ngx-paypal';
import { ICoreService } from "@alphaa/services/ionic/icore.service";
import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';
import { environment } from '@envs/environment';
import { BaseViewI } from '@alphaa/models/ionic/baseViewI.model';

@Component({
  selector: 'modal-cart',
  templateUrl: './cart.modal.html',
  styleUrls: ['./cart.modal.scss'],
})
export class CartModal extends BaseViewI implements OnInit {
  public payPalConfig?: IPayPalConfig;

  SKIS = SKIS;

  cartMode:string = 'cart';

  constructor(public util: UtilService, public core: ICoreService, public translate: TranslateService, private modalCtrl: ModalController,) { 
    super(core);
  }

  ngOnInit() {
    this.initConfig();
  }

  close() {
    this.modalCtrl.dismiss();
  }

  setCheckout() {
    this.cartMode='checkout';
    this.initConfig();
  }

  private initConfig(): void {
    //this.util.initConfig()
    this.payPalConfig = this.util.payPalConfig;
  }
}
