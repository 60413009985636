import { indexOfMax, similarity } from '@alphaa/utils/math.utils';
import { environment } from 'environments/environment';
import _ from 'lodash';
import { User } from './user.model';

function pruneEmpty(obj) {
    return (function prune(current) {
        _.forOwn(current, function (value, key) {
            if (_.isUndefined(value) || _.isNull(value) || _.isNaN(value) || (_.isString(value) && _.isEmpty(value)) || (_.isObject(value) && _.isEmpty(prune(value)))) {
                delete current[key];
            }
        });
        // remove any leftover undefined values from the delete
        // operation on an array
        if (_.isArray(current)) _.pull(current, undefined);

        return current;
    })(_.cloneDeep(obj)); // Do not modify the original object, create a clone instead
}
export class DynClass {
    readonly verbose: false;
    readonly saveName;
    readonly dynProperties: string[] = ['dynProperties', 'isProd', 'verbose', 'saveName'];

    public isProd = environment.production;

    /*constructor(data?: any) {
        if(data) {
            console.log('this',this);
            for (let key in data) {
                console.log(' >',key);
                this[key] = data[key];
            }
            console.log(' >>>',this);
        }
    }*/

    public assign(parameters: {}) {
        /*const properties = this.getProperties();
      const types = properties.map(p => typeof this[p]);

      let converted_parameters = {};
      for (const p in parameters) {
        let value = this[p];
        let class_t = this.constructor;
        let class_tp = typeof class_t[p];
        let p_type = typeof this[p];
        let type_name = this[p].constructor.name;
        let p_type2 = this[p].constructor;
        let p_type3 = typeof this[p][0];

        if (p_type == 'object' && Object.keys(parameters).includes(p)) {
          converted_parameters[p] = parameters[p];
        }
      }*/
        Object.assign(this, parameters);
        return this;
    }

    autoMapFromDictToList(data: {}) {
        let list: any[] = [];

        let cols = this.getProperties();
        let cols_s = cols.map(c => c.toLocaleLowerCase().trim());
        const data_cols = Object.keys(data);

        let columns: {} = {};

        let identified = [];

        data_cols.forEach(columnName => {
            const replaced = columnName
                ?.replace(/[^a-z0-9]/gi, '')
                .toLocaleLowerCase()
                .trim();
            let found_index = cols_s.findIndex(cs => cs == replaced);

            if (found_index === undefined || found_index === -1) {
                let values = cols.map(col => similarity(col, columnName));
                found_index = indexOfMax(values);
            }

            if (identified.indexOf(cols[found_index]) === -1) {
                identified.push(cols[found_index]);
                columns[cols[found_index]] = columnName;
            }
        });

        console.log('Mapping ', columns);

        data[String(Object.values(columns)[0])].forEach((value, index) => {
            let dict: {} = {};
            Object.keys(columns).forEach(k => {
                let valueType = typeof this.getProperty(k);
                let value = data[columns[k]][index];
                if (valueType == 'number') value = Number(value?.replace(/,/g, '.'));
                dict[k] = value;
            });

            let factor = Object.assign(Object.create(this), dict);

            list.push(factor);
        });
        return list;
    }

    public log(text: string) {
        if (this.verbose) {
            console.log(text);
        }
    }

    public getMethods() {
        return Object.keys(this);
    }

    public getProperties(exludes: string[] = []) {
        //TODO: simplify
        let methods: string[] = this.getMethods();
        let properties = Object.getOwnPropertyNames(this);
        let types = properties.map(p => typeof this.getProperty(p));
        //return properties.filter(x => methods.indexOf(x) === -1);
        let output = properties.filter(p => !(this.dynProperties.indexOf(p) !== -1) && !(exludes.indexOf(p) !== -1));
        return output;
    }

    public get_values(exludes: string[] = []) {
        let output = {};
        this.getProperties(exludes).forEach(p => {
            output[p] = this.getProperty(p);
        });
        return output;
    }

    public getProperty(name: string) {
        if (this.hasOwnProperty(name)) {
            return this[name];
        }
        return undefined;
    }

    public setProperty(name: string, value: any) {
        //if (this.hasOwnProperty(name)) {
        this[name] = value;
        //}
    }

    public savedSession(name: string, defaultValue?: any): any {
        name = this.saveName ? this.saveName + '_' + name : name;

        const value = sessionStorage.getItem(name);
        let parsed;
        try {
            parsed = JSON.parse(value);
        } catch (e) {
            parsed = value;
        }
        return !parsed ? defaultValue : parsed;
    }

    public saveSession(name: string, element: any): void {
        name = this.saveName ? this.saveName + '_' + name : name;

        if (element) {
            sessionStorage.setItem(name, JSON.stringify(element));
        }
    }

    public removeSession(name: string) {
        name = this.saveName ? this.saveName + '_' + name : name;
        sessionStorage.removeItem(name);
    }

    public saved(name: string, defaultValue?: any): any {
        name = this.saveName ? this.saveName + '_' + name : name;
        name = name.trim();
        const value = localStorage.getItem(name);
        let parsed;
        try {
            parsed = JSON.parse(value);
        } catch (e) {
            parsed = value;
        }
        if (parsed === 'undefined') parsed = undefined;
        return parsed === undefined || parsed === null ? defaultValue : parsed;
    }

    public savedMulti(elements: any[]) {
        elements.forEach(e => {
            if (Array.isArray(e)) {
                this.setProperty(e[0], this.saved(e[0], e[1]));
            } else {
                this.setProperty(e, this.saved(e[0]));
            }
        });
    }

    public save(name: string, element: any): void {
        name = this.saveName ? this.saveName + '_' + name : name;
        name = name.trim();

        let value = JSON.stringify(element);
        localStorage.setItem(name, value);
    }

    public saveMulti(elements: [] | {}) {
        if (Array.isArray(elements)) {
            elements.forEach(e => this.save(e, this.getProperty(e)));
        } else {
            for (let [key, value] of Object.entries(elements)) {
                this.save(key, value);
            }
        }
    }

    public remove(name: string) {
        name = this.saveName ? this.saveName + '_' + name : name;
        localStorage.removeItem(name);
    }

    public setSelection(selected: any, list: any[], cache: string, configed?: any) {
        if (this.saveName) {
            cache = this.saveName + '_' + cache;
        }

        let value: any = selected;

        if (!selected || (list && list.indexOf(selected) === -1)) {
            let cached = this.saved(cache);

            if (cached && list.indexOf(cached) !== -1) {
                value = cached;
            } else if (configed && list.indexOf(configed) !== -1) {
                value = configed;
            } else if (list && list.length != 0) {
                value = list[0];
            }
        }
        return value;
    }

    public setSelectionList(selected: any[], list: any[], cache: string, configed?: any[]) {
        let value: any = selected;

        if (!selected) {
            let cached = this.saved(cache);
            if (cached) {
                value = cached;
            } else if (configed) {
                // list.includes(configed)
                value = configed;
            } else {
                value = list;
            }
        }
        return value;
    }

    public cache() {}

    public copy() {
        return _.cloneDeep(this);
    }

    public to_json(not_empty: boolean = false, exludes: string[] = []) {
        let output = this.get_values(exludes);
        if (not_empty) {
            output = pruneEmpty(this);
        } else {
            output = this.copy();
        }
        let out = {};
        for (let key in output) {
            let value = Array.isArray(output[key]) ? '[' + output[key].join(';') + ']' : JSON.stringify(output[key]);
            out[key] = _.isString(output[key]) ? output[key] : value;
        }
        return out;
    }

    public to_api_parameters(not_empty: boolean = false, exludes: string[] = []) {
        let output = this.get_values(exludes);
        if (not_empty) {
            output = pruneEmpty(this);
        } else {
            output = this.copy();
        }
        let out = [];
        for (let key in output) {
            let value = Array.isArray(output[key]) ? '[' + output[key].join(';') + ']' : JSON.stringify(output[key]);
            if (value) value = value?.replace(/^"(.+)"$/, '$1');
            out.push(`${key}=${value}`);
        }
        return out.join('&');
    }
}

export class DynView extends DynClass {
    public currentUser: User;
    readonly saveName;
    public isProd = environment.production;

    constructor() {
        super();
    }
    public cache() {}
    public setSelectionList(selected: any[], list: any[], cache: string, configed?: any[]) {
        let value: any = selected;

        if (!selected) {
            let cached = this.saved(cache);
            if (cached) {
                value = cached;
            } else if (configed) {
                // list.includes(configed)
                value = configed;
            } else {
                value = list;
            }
        }
        return value;
    }
    public setSelection(selected: any, list: any[], cache: string, configed?: any) {
        if (this.saveName) {
            cache = this.saveName + '_' + cache;
        }

        let value: any = selected;

        if (!selected || (list && list.indexOf(selected) === -1)) {
            let cached = this.saved(cache);

            if (cached && list.indexOf(cached) !== -1) {
                value = cached;
            } else if (configed && list.indexOf(configed)) {
                value = configed;
            } else if (list && list.length != 0) {
                value = list[0];
            }
        }
        return value;
    }
    public saved(name: string, defaultValue?: any) {
        if (this.saveName) {
            name = this.saveName + '_' + name;
        }
        let value = undefined;
        try {
            value = JSON.parse(localStorage.getItem(name));
        } catch (e) {
            console.log(e);
            if (defaultValue) {
                value = defaultValue;
            }
        }
        if (value === undefined || value === null) {
            value = defaultValue;
        }

        return value;
    }
    public save(name: string, element: any) {
        if (this.saveName) {
            name = this.saveName + '_' + name;
        }
        let value;
        try {
            value = JSON.stringify(element);
        } catch (e) {
            console.log(e);
        }

        if (value) {
            localStorage.setItem(name, value);
        }
    }
}

export abstract class DynStaticClass {
    public static class = DynStaticClass;

    public static getMethods() {
        return ObjectExplorer.getMethods(DynStaticClass.class);
    }

    public static getProperties() {
        //TODO: simplify
        return ObjectExplorer.getProperties(DynStaticClass.class);
    }

    public static getProperty(name: string) {
        return ObjectExplorer.getProperty(DynStaticClass.class, name);
    }
}

export abstract class ObjectExplorer {
    public static getMethods(classObject: Object) {
        return Object.keys(Object);
    }

    public static getProperties(classObject: Object) {
        //TODO: simplify
        let methods: string[] = ObjectExplorer.getMethods(classObject);
        let properties: string[] = Object.getOwnPropertyNames(classObject);
        return properties.filter(x => methods.indexOf(x) === -1);
    }

    public static getProperty(classObject: Object, name: string) {
        if (classObject.hasOwnProperty(name)) {
            return classObject[name];
        }
        return '';
    }
}
