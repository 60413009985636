import { getBoolean } from "@alphaa/utils/common.utils";
import { onlyLettersAndNumbers } from "@alphaa/utils/string.utils";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { DynClass } from "./dyn.model";

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export class Pagination extends DynClass {
  total: number = 0;
  total_pages: number = 0;
  page: number = 0;
  previous_page: number = 0;
  next_page: number = 0;
  per_page: number = 0;

  isNext(): boolean {
    return this.next_page != this.page;
  }

  isPrevious(): boolean {
    return this.page != this.previous_page;
  }

  getMergedPages(): number[] {
    const firstPages = this.getFirstPages();
    const lastPages = this.getLastPages();

    const uniquePages = new Set([...firstPages, ...lastPages]); // Combine les deux tableaux et élimine les doublons
    const pages = Array.from(uniquePages); // Convertit l'ensemble en tableau
    return pages.filter((p) => p != this.page);
  }

  getFirstPages(): number[] {
    return Array.from(
      { length: Math.min(this.page + 2, this.total_pages) },
      (_, i) => i + 1
    ).filter((p) => p != this.page && !this.next_page);
  }

  getLastPages(): number[] {
    return Array.from(
      { length: Math.min(3, this.total_pages - this.page) },
      (_, i) => this.total_pages - i
    ).filter((p) => p != this.page && !this.previous_page);
  }

  isPageGap(): boolean {
    return this.getFirstPages().some(
      (element) => this.getLastPages().indexOf(element) !== -1
    );
  }
}

export const DEFAULT_PAGINATION = new Pagination();

/**
 * ApiAnswer class processes the response from an API request.
 * @template T: The data type of the data property.
 */
export class ApiAnswer<T = any> extends DynClass {
  data: T;
  valid: boolean = false;
  error: boolean = false;
  warning: boolean = false;
  timeout: boolean = false;
  status: number = 200;
  token_status: string = "";
  empty: boolean = true;
  status_description: string = "";
  pagination: Pagination;

  /**
   * Constructor initializes the ApiAnswer object with the response from an API request.
   * @param {string} url - The API endpoint URL.
   * @param {any} params - The parameters used for the API request.
   * @param {HttpResponse<any> | HttpErrorResponse} answer - The response from the API request.
   */
  constructor(
    url: string,
    params: any,
    answer: HttpResponse<any> | HttpErrorResponse
  ) {
    super();
    const { local, body, isIntegrated } = this.extractInfo(url, answer);
    this.initializeProperties(local, body, isIntegrated, answer);
    this.parseProperties();
  }

  private extractInfo(
    url: string,
    answer: HttpResponse<any> | HttpErrorResponse
  ): { local: boolean; body: any; isIntegrated: boolean } {
    const local = url.substring(0, 2) === "./";
    const body = answer instanceof HttpResponse ? answer.body : answer.error;

    const isIntegrated =
      body &&
      (["status", "status_description", "data", "warning", "error"].every(
        (e) => Object.keys(answer).indexOf(e) !== -1
      ) ||
        ["status", "status_description", "data", "warning", "error"].every(
          (e) => Object.keys(body).indexOf(e) !== -1
        ));

    return { local, body, isIntegrated };
  }

  private initializeProperties(
    local: boolean,
    body: any,
    isIntegrated: boolean,
    answer: HttpResponse<any> | HttpErrorResponse
  ): void {
    if (local) {
      this.setLocalProperties(body);
    } else {
      if (isIntegrated) {
        this.setIntegratedProperties(body, answer);
      } else {
        if (isIntegrated) {
          this.setIntegratedProperties(body, answer);
        } else {
          this.setExternalProperties(body, answer);
        }
        this.valid =
          (!this.warning && this.status >= 200 && this.status < 400) ||
          "" + this.status === "success";
        this.empty = isEmpty(this.data);
      }
      this.valid = !this.warning && this.status >= 200 && this.status < 400;
      this.empty = isEmpty(this.data);
    }
  }

  private setLocalProperties(body: any): void {
    this.status = 200;
    this.valid = true;
    this.data = body as T;
    this.empty = isEmpty(this.data);
  }

  private setIntegratedProperties(
    body: any,
    answer: HttpResponse<any> | HttpErrorResponse
  ): void {
    this.status = body["status_code"];
    this.error = getBoolean(body["error"]);
    this.data = body["data"] as T;
    this.status_description = body["status_description"];
    this.warning = body["warning"] ? true : false;
    this.timeout = body["status"] === "timeout";

    const xPagination = answer.headers.get("x-pagination");

    if (xPagination) {
      this.pagination = new Pagination().assign(
        JSON.parse(xPagination.replace(/'/g, '"'))
      );

      /*if(this.pagination.total !== undefined && Array.isArray(this.data) && this.data.length == 2 && Number.isInteger(Number(this.data[1]))) {
            this.data = this.data[0];
        }*/
    }
  }

  private setExternalProperties(
    body: any,
    answer: HttpResponse<any> | HttpErrorResponse
  ): void {
    this.data = body as T;
    let xPagination;

    this.error =
      answer.status < 200 ||
      answer.status > 299 ||
      (answer.headers ? getBoolean(answer.headers.get("x-error")) : false);

    this.status = answer.status;

    if (answer.headers) {
      this.token_status = answer.headers.get("x-token-status");
      this.warning = getBoolean(answer.headers.get("x-warning"));
      this.status_description =
        answer.headers.get("x-status-description")?.replace(/\|/g, "\n") ??
        answer.statusText;
      xPagination = answer.headers.get("x-pagination");
      if (xPagination) {
        this.pagination = new Pagination().assign(
          JSON.parse(xPagination.replace(/'/g, '"'))
        );
      }
    }
  }

  private parseProperties(): void {
    ["status", "status_description"].forEach((p) => {
      if (!onlyLettersAndNumbers(p)) {
        try {
          this.setProperty(p, JSON.parse(this.getProperty(p)));

          if (Array.isArray(this.getProperty(p))) {
            this.setProperty(p, this.getProperty(p).join("\n"));
          }
        } catch (error) {}
      }
    });
  }
}
