interface LinkParams {
    uuid?: string;
    index?: number;
    page?: string;
    mode?:string;
  }
  
  interface SubMenu {
    title: string;
    stitle?: string;
    link: string;
    params?: LinkParams;
    transp?: number[];
    white?: number[];
    noGroupTitle? : boolean;
    img?:string;
  }
  
 export interface MenuItem {
    title: string;
    link: string;
    img?: string;
    row: number;
    ht?: boolean;
    params?: LinkParams;
    subs?: SubMenu[];
    topMenu? :boolean;
    icon?:string;
  }
  
  export const menuLinks: MenuItem[] = [
    {
      title: 'MENU.PRODUCTS.TITLE',
      link: '/products',
      row: 0,
      img: 'TRIO.png',
      ht: true,
      subs: [
        {
          title: 'MENU.PRODUCTS.SKIS.TITLE',
          link: '/products',
          params: { mode:'skis' },
          transp: [0, 1, 2, 3, 4],
          white: [2],
          img: 'assets/skis/skis_small.jpg'
        },
        {
          title: 'MENU.PRODUCTS.TEXTILE.TITLE',
          link: '/products',
          params: { mode:'textiles' },
          transp: [0, 1, 2, 3, 4],
          white: [2],
          img: 'assets/skis/ski-fond-neige.jpg'
        }
      ],
    },
    {
      title: 'MENU.TECHNOLOGY.TITLE',
      link: '/adn2',
      img: 'logo.png',
      row: 1,
      params: { index: 0 },
      subs: [
        {
          title: 'MENU.TECHNOLOGY.ECOCONCEPTION',
          //stitle: 'MENU.VALUES.SUSTAINABLE_DEVELOPMENT_SHORT',
          link: '/ecodesign',
          transp: [0, 1, 2, 3, 4],
          white: [2],
          img: 'assets/new/images/1.png'
        },
        {
          title: 'MENU.TECHNOLOGY.MATERIALS',
          link: '/materials',
          transp: [0, 1, 2, 3, 4],
          white: [1, 4],
          img: 'assets/new/images/2.png'
        },
        {
          title: 'MENU.TECHNOLOGY.RECYCLABILITY',
          link: '/recycling',
          transp: [0, 1, 2, 3],
          white: [0, 2, 3],
          img: 'assets/new/images/3.png'
        },
        {
          title: 'MENU.TECHNOLOGY.INGREDIENT_BRAND',
          link: '/ingredientbrand',
          transp: [0, 1, 2, 3],
          white: [0, 2, 3],
          img: 'assets/new/images/main/ingredients.jpg'
        },
      ],
    },
    {
      title: 'MENU.COMPAGNY.TITLE',
      link: '/approach',
      params: { index: 7 },
      img: 'backn2.jpg',
      row: 2,
      subs: [
        {
          title: 'MENU.COMPAGNY.VALUES',
          link: '/values',
          transp: [0, 1, 2, 3],
          white: [0, 1, 2, 3],
          img: 'assets/images/pieds.jpg'
        },
        {
          title: 'MENU.COMPAGNY.HISTORY',
          link: '/history',
          transp: [0, 1, 2, 3],
          white: [1, 2, 3],
          img: 'assets/images/tour.png'
        },
        {
          title: 'MENU.COMPAGNY.TEAM',
          link: '/team',
          transp: [0, 1, 2, 3],
          img: 'assets/team/team.jpg'
        },
        {
          title: 'MENU.COMPAGNY.AMBASSADORS',
          link: '/ambassadors',
          transp: [0, 1, 2, 3],
          img: 'assets/images/xsalomon4.png'
        },
        {
          title: 'MENU.COMPAGNY.BLOG',
          link: '/blog',
          transp: [0, 1, 2, 3],
          img: 'assets/images/int_col.jpg'
        },
      ],
    },
    {
      title: 'MENU.SERVICES.TITLE',
      link: '/contact',
      img: 'marque.PNG',
      row: 3,
      subs: [
        {
          title: 'MENU.SERVICES.MAINTENANCE',
          link: '/maintenance',
          transp: [0, 1],
          white: [0, 1],
          img: 'assets/new/images/IMG-20231220-WA0003.jpg'
        },
        {
          title: 'MENU.SERVICES.DELIVARY',
          noGroupTitle: true,
          link: '/delivery',
          transp: [1],
          white: [1],
          img: 'assets/images/factory.jpg'
        },
        { title: 'MENU.SERVICES.CONTACT', link: '/contact', img:'assets/new/images/received_726969952452330.jpeg'  },
        {
          title: 'MENU.SERVICES.FAQ',
          link: '/faq',
          transp: [0],
          white: [0],
          img: 'assets/new/images/received_453835910057272.jpeg'
        }
      ],
    }
  ];
  