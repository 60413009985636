import { Injectable } from "@angular/core";
import { filter } from "rxjs/operators";
import { NavigationEnd, Router, ActivatedRoute } from "@angular/router";
//import { Platform } from '@ionic/angular';

import { LoggerService } from "./logger.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { HttpService } from "@alphaa/services/http.service";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: "root",
})
export class CoreService {
  hideTabBarPages = ["new-group"];

  routeParamPages: string[] = ["product-details"];

  hideSideMenuPages: string[] = [""];
  debug: boolean = false;

  constructor(
    public log: LoggerService,
    public http: HttpService,
    public router: Router,
    //public platform: Platform,
    public route: ActivatedRoute,
    public device: DeviceDetectorService,
    public spinner: NgxSpinnerService
  ) {
    /*this.platform.ready().then(() => {
      this.navEvents();
    });*/

    this.debug = localStorage.getItem("debug") == 'y';
  }

  public goTo(url: string, parameters: {}) {
    const queryParams = Object.assign({}, parameters);
    this.router.navigate([url], {
      queryParams: queryParams,
      queryParamsHandling: "merge",
    });
  }

  public hideTabs() {
    const tabBar = document.getElementById("myTabBar");
    if (tabBar.style.display !== "none") tabBar.style.display = "none";
  }

  public showTabs() {
    const tabBar = document.getElementById("myTabBar");
    if (tabBar && tabBar.style.display !== "flex")
      tabBar.style.display = "flex";
  }

  // A simple subscription that tells us what page we're currently navigating to.
  private navEvents() {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((e: any) => {
        this.showHideTabs(e);
      });
  }

  private showHideTabs(e: any) {
    // Result:  e.url: "/tabs/groups/new-group?type=group"
    // Split the URL up into an array.
    const urlArray = e.url.split("/");
    // Result: urlArray: ["", "tabs", "groups", "new-group?type=group"]// Grab the parentUrl
    const pageUrlParent = urlArray[urlArray.length - 2];
    // Grab the last page url.
    const pageUrl = urlArray[urlArray.length - 1];
    // Result: new-group?type=group
    const page = pageUrl.split("?")[0];
    // Result: new-group// Check if it's a routeParamPage that we need to hide on
    const hideParamPage =
      this.routeParamPages.indexOf(pageUrlParent) > -1 && !isNaN(Number(page));
    // Check if we should hide or show tabs.
    const shouldHide = this.hideTabBarPages.indexOf(page) > -1 || hideParamPage;
    // Result: true    // Not ideal to set the timeout, but I haven't figured out a better method to wait until the page is in transition...

    const shouldNotShowSideMenu = this.hideSideMenuPages.indexOf(page) > -1;

    this.showHideMenu(shouldNotShowSideMenu);

    try {
      setTimeout(() => (shouldHide ? this.hideTabs() : this.showTabs()), 300);
    } catch (err) {}
  }

  public showHideMenu(hide: boolean = false) {
    try {
      const menu = document.getElementById("slidingMenu");
      const menub = document.getElementById("menu-button");

      if (!hide && menu.classList.contains("hide")) {
        menu.classList.remove("hide");
      } else if (hide && !menu.classList.contains("hide")) {
        menu.classList.add("hide");
      }

      if (!hide && menub.classList.contains("hide")) {
        menub.classList.remove("hide");
      } else if (hide && !menub.classList.contains("hide")) {
        menub.classList.add("hide");
      }
    } catch (err) {}
  }

  public getParam(name, defaultValue = undefined) {
    const results = new RegExp("[\\?&]" + name + "=([^&#]*)").exec(
      window.location.href
    );
    if (!results) {
      return defaultValue;
    }
    return results[1] || 0;
  }
}
