import { Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '@envs/environment';
import { IonContent, IonSlides } from '@ionic/angular';
import { UtilService } from '@services/util.service';

import {Ski, SKIS} from './skis';
import { BaseView } from '../../alphaa/models/views.model';
import { BaseViewI } from '../../alphaa/models/ionic/baseViewI.model';
import { ICoreService } from '@alphaa/services/ionic/icore.service';
import { filter } from 'rxjs/operators';

function throttle(func, limit) {
  let inThrottle;
  return function() {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => inThrottle = false, limit);
    }
  };
}

function detecterTitres(titres) {
  titres.forEach(titre => {
    const positionTitre = titre.getBoundingClientRect().top; // position du titre
    const positionViewport = window.innerHeight; // taille de la fenêtre visible

    // si le titre est visible à l'écran
    if (positionTitre < positionViewport) {
      titre.classList.add('apparaitre'); // ajouter la classe pour faire apparaitre le titre
    } else {
      titre.classList.remove('apparaitre'); // enlever la classe si le titre sort de l'écran
    }
  });
}


function detecterTitres2(titres) {
  titres.forEach(titre => {
    const positionTitre = titre.getBoundingClientRect().top; // position du titre
    const positionViewport = window.innerHeight; // taille de la fenêtre visible
    const positionCentre = positionViewport / 2; // position du centre de la fenêtre visible

    // distance entre la position du titre et la position du centre de la fenêtre visible
    const distanceCentre = Math.abs(positionCentre - positionTitre);

    // seuil de distance pour l'apparition du titre
    const seuil = positionViewport * 0.1; // ajustez ce seuil selon vos préférences

    // si le titre est suffisamment proche du centre de la fenêtre visible
    if (distanceCentre < seuil) {
      titre.classList.add('apparaitre'); // ajouter la classe pour faire apparaitre le titre
    } else {
      titre.classList.remove('apparaitre'); // enlever la classe si le titre est trop éloigné
    }
  });
}

function detecterTitres3(event) {
  const titres = document.querySelectorAll('h2');
  titres.forEach(element => {
    const height = element.offsetHeight;
    const positionElement = element.getBoundingClientRect().top; // position du titre
    const positionViewport = window.innerHeight; // taille de la fenêtre visible
    const positionCentre = positionViewport / 2; // position du centre de la fenêtre visible

    // distance entre la position du titre et la position du centre de la fenêtre visible
    const distanceCentre = Math.abs(positionElement + height/2 - positionCentre);

    // calculer le décalage horizontal en fonction de la distance entre la position du titre et la position du centre de la fenêtre visible
    const decale = (1 - 0.5*(distanceCentre / positionCentre));

    // @ts-ignore
    element.style.opacity = `${decale}`; // appliquer la translation au titre

    // translation horizontale pour le titre
    const translation = `translateX(${-(1 -decale)*20}%)`;
    element.style.transform = translation;

    // si le titre est suffisamment proche du centre de la fenêtre visible
    /*if (distanceCentre < seuil) {
      titre.style.transform = translation; // appliquer la translation au titre
      titre.classList.add('apparaitre'); // ajouter la classe pour rendre le titre visible
    } else {
      titre.style.transform = 'translateX(0)'; // enlever la translation si le titre est trop éloigné
      titre.classList.remove('apparaitre'); // enlever la classe si le titre est trop éloigné
    }*/
  });
}

function detecterTitresH1(titres) {
  titres.forEach(titre => {
    const positionTitre = titre.getBoundingClientRect().top; // position du titre
    const positionViewport = window.innerHeight; // taille de la fenêtre visible
    const positionCentre = positionViewport / 2; // position du centre de la fenêtre visible

    // distance entre la position du titre et la position du centre de la fenêtre visible
    const distanceCentre = Math.abs(positionCentre - positionTitre);

    // seuil de distance pour l'apparition du titre
    const seuil = positionViewport * 0.1; // ajustez ce seuil selon vos préférences

    // décalage horizontal maximal pour le titre
    const decaleMax = 100; // ajustez ce décalage selon vos préférences

    // calculer le décalage horizontal en fonction de la distance entre la position du titre et la position du centre de la fenêtre visible
    const decale = decaleMax * (1 - (distanceCentre / positionCentre));

    // translation horizontale pour le titre
    const translation = `translateX(${decale}px)`;

    // si le titre est suffisamment proche du centre de la fenêtre visible
    if (distanceCentre < seuil) {
      titre.style.transform = translation; // appliquer la translation au titre
      titre.classList.add('apparaitre'); // ajouter la classe pour rendre le titre visible
    } else {
      titre.style.transform = 'translateX(0)'; // enlever la translation si le titre est trop éloigné
      titre.classList.remove('apparaitre'); // enlever la classe si le titre est trop éloigné
    }
  });
}

function appearTitresH1(titres) {
  titres.forEach(titre => {
    titre.classList.add('apparaitre');
  });
}

@Component({
  selector: 'app-adn-view',
  template: '',
})
export class ADNView extends BaseViewI {
    @ViewChild(IonContent) content: IonContent;
    @ViewChild("header") header: HTMLElement;
    @ViewChild("mySlider") slider: IonSlides;

    debouncedScroll;

    video:string = '';

    showVideo: string = '';

    private isAtEnd = false;

    cumulativeDeltaY: number = 0;

    constructor(protected core: ICoreService, protected route: ActivatedRoute, protected router: Router, public util: UtilService) {
        super(core);
  
        this.scrollDown = this.scrollDown.bind(this);
        this.scrollUp = this.scrollUp.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
      
        this.debouncedScroll = this.debounce(this.handleScroll, 300);
        document.addEventListener('wheel', this.handleScroll, {passive: false});
     }
     
     ngOnDestroy() {
      document.removeEventListener('wheel', this.handleScroll);
    }

    async slideChanged() {
      this.isAtEnd = await this.slider.isEnd();
    }
    
    
    scrollToTop() {
      this.content.scrollToTop(400);
  }

  scrollDown(event) {
    if(this.slider) this.slider.slideNext();
    //setTimeout(function () { detecterTitres3(event);  }, 50);
  }

  scrollUp(event) {
    if(this.slider) this.slider.slidePrev();
    //setTimeout(function () { detecterTitres3(event);  }, 50);
  }

  onScroll(event) {
    this.appear(event);

    this.util.isTop = event.detail.currentY == 0;

    const direction = event.detail.deltaY > 0 ? 'down' : 'up';
    this.debouncedScroll(direction);
  }

  handleScroll(event: WheelEvent) {
    return;
    let isSlider = document.getElementById('mySlider');
    if (!isSlider) return; 
    
    const sensitivityThreshold = 200;

    if (this.slider) {
      this.cumulativeDeltaY += event.deltaY;

      if (Math.abs(this.cumulativeDeltaY) > sensitivityThreshold) {
        const direction = this.cumulativeDeltaY > 0 ? 'down' : 'up';
        this.cumulativeDeltaY = 0;
        if(!this.isAtEnd && direction === 'down') {
          event.preventDefault();
        }

        if (direction === 'down') {
          this.scrollDown(event);
        } else if (direction === 'up') {
          this.scrollUp(event);
        }
      }
    }
  }
  

  debounce(func, wait) {
    let timeout;
    return function(...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }

     set360(ski: Ski, disable:boolean=false) {
      this.showVideo = (this.showVideo === ski.uuid) || disable ? '' : ski.uuid;
      if (this.showVideo != '') {
        this.checkVideoStatus();
      }
    }

     checkVideoStatus() {
      const videoRefs = document.querySelectorAll('.v360');
      videoRefs.forEach((videoRef: Element) => {
        const video = videoRef as HTMLVideoElement;  // Cast de type
        video.load();
        if (video.paused) {
          
          video.play().catch(error => {
            // Gérer les erreurs ici
            console.error('Erreur lors de la lecture de la vidéo:', error);
          });
        }
      });
    }
    

     playPause(id:string) {
      if(this.video != id) {
        this.video = id;
        this.util.hideMenu = true;
      }
      var myVideo: any = document.getElementById(id);

      if (myVideo.paused) {
        myVideo.play();
      }
      else {
        myVideo.pause();
      }
    }

    closeVideo() {
      var myVideo: any = document.getElementById(this.video);

      if (!myVideo.paused) {
        myVideo.pause();
      }
      this.video = '';
      this.util.hideMenu = false;
    }

    ngOnInit() {
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(() => {
          this.util.activeSlide = 0;
          this.util.processContext();
        });
    }
    

     appear(event) {
      const elements = document.querySelectorAll('.appear'); 

      elements.forEach((element,i) => {
        // @ts-ignore
        const height = element.offsetHeight;
        const positionElement = element.getBoundingClientRect().top; // position du titre
        const positionViewport = window.innerHeight; // taille de la fenêtre visible
        const positionCentre = positionViewport / 2; // position du centre de la fenêtre visible
        let positionScroll = event.detail.scrollTop;
    
        // distance entre la position du titre et la position du centre de la fenêtre visible
        const distanceCentre = Math.abs(positionElement + height/2 - positionCentre);

        // calculer le décalage horizontal en fonction de la distance entre la position du titre et la position du centre de la fenêtre visible
        const decale = (1 - 0.5*(distanceCentre / positionCentre));
    
        // @ts-ignore
        element.style.opacity = `${decale}`; // appliquer la translation au titre
      });
     }

     ionViewDidEnter() {
      this.checkTitles()
      this.util.processContext();

      /*this.route.queryParams.subscribe(params => {
        this.checkTitles();
      });*/
     }

    checkTitles() {
      const titresH1 = document.querySelectorAll('h1'); // sélectionne tous les titres h1

      appearTitresH1(titresH1); // détecte les titres h1

      //detecterTitres3(undefined);
    }

    goToSki(ski:Ski) {
      //this.router.navigate(['item'], {queryParams:{uuid:ski.uuid}});
      this.core.goTo("item", { uuid: ski.uuid });
      this.content.scrollToTop(400);
    }

    goTo(url:string, parameters: {}) {
      this.core.goTo(url, parameters);
      this.content.scrollToTop(400);
    }


}