import { DynClass } from "@alphaa/models/dyn.model";

export class Stats {
    distance:number;
    recycled:number;
    maxSpeed: number;
    stations: number;
    pays: number;
    utilisateurs: number;
}


export class Dimension {
    d: number;
    s:number;
    p:number;
    t:number;
    r:string;
    po:number;
}

export class Color {
    color: string;
    colors: string[];
}


const impact = [{v:82,p:80}, {v:7,p:70}, {v:520,p:60}, {v:90,p:95}, {v:8,p:80}, {v:96,p:95}];

export interface Language {
    fr: string;
    en: string;
  }
  
  export interface Composition {
    text: Language;
    icon: string;
  }
  
  export interface Dimension {
    d: number;
    s: number;
    p: number;
    t: number;
    r: string;
    po: number;
    nb?: number;
  }
  
  export interface Color {
    color: string;
    colors: string[];
  }

  export const PRODUCT_VERSION = 2;
  
const DEFAULT_LANGUAGE = {fr:'',en:''}
  export class Ski extends DynClass {
    nb?: number;
    name: string;
    category:string;
    image: string = '';
    images?: string[] = []; // rendu optionnel
    type?: Language = DEFAULT_LANGUAGE; // rendu optionnel
    description?: Language = DEFAULT_LANGUAGE; // rendu optionnel
    note?: Language = DEFAULT_LANGUAGE; // rendu optionnel
    color?: string;
    mark?: string;
    price?: number; // rendu optionnel
    sizes?: Dimension[]; // rendu optionnel
    rating?: number; // rendu optionnel
    amount?: number; // rendu optionnel
    uuid?: string; // rendu optionnel
    colors?: Color[]; // rendu optionnel
    bgcolor?: string; // rendu optionnel
    scores?: number[]; // rendu optionnel
    impacts?: any[] = []; // rendu optionnel
    delivery: number = 0;
    version = PRODUCT_VERSION;

    categoriesScores?: any[]; // rendu optionnel
    score?: any; // rendu optionnel
    stats?: Stats; // rendu optionnel

    levels?: number[]; // rendu optionnel
    types?: number[]; // rendu optionnel

    composition?: Composition[]; // rendu optionnel
    is360: boolean;

    url(index?:number) {
      if(this.category=='skis') {
        index = index !== undefined ? (index == -1 ? 4 : index) : 2;
        return 'url(assets/skis/'+this.name.toUpperCase()+'/'+this.images[index]+'.jpg)';
      }
      index = index !== undefined ? (index == -1 ? 4 : index) : 2;
      return 'url(assets/skis/OTHER/'+this.images[index] + '.jpg)';
    }
}


  const FIXS = {fr:"Les skis sont livrés avec plaques et fixations E M11 de la marque Salomon.", en:" Les skis sont livrés avec plaques et fixations E M11 de la marque Salomon."}

  const SEM_REB = { text: { fr: "Semelle rebroyée", en: "Recycled base" }, icon: "fa-recycle" };
  const CAR_ACIER = { text: { fr: "Carres en acier", en: "Steel edges" }, icon: "fa-check" };
  const ABS_CHAMP = { text: { fr: "Chants ABS", en: "ABS sidewalls" }, icon: "fa-check" };
  const NOYAU_PEU = { text: { fr: "Noyau en bois", en: "Wood core" }, icon: "fa-check" };
  const NOYAU_PEU_POLY = { text: { fr: "Noyau en bois et fine couche de mousse polyuréthane", en: "Hybrid poplar wood core, polyurethane foam" }, icon: "fa-check" };
  const RENF_ALU = { text: { fr: "Renfort en aluminium", en: "Aluminum reinforcement" }, icon: "fa-check" };
  const FIB_VER = { text: { fr: "Fibres de verre", en: "Triaxial fiberglass" }, icon: "fa-check" };
  const TOP_REC = { text: { fr: "Topsheet en Polyamide recyclée et rebroyé", en: "Polyamid top sheet recycled and shredded" }, icon: "fa-check" };
  const TOP_REC_BIO = { text: { fr: "Topsheet en Polyamide 30% recyclée et 27% bio-sourcée", en: "Polyamid top sheet 30% recycled and 27% bio source Pa" }, icon: "fa-check" };
  const TSHIRT = { text: {fr:"T-Shirts coton bio made in France à Roanne"}}
    /*         { text: { fr: "2 couches de fibre de verre, une uni au centre pour redonner de la souplesse et une triaxial au-dessous tout le long x 2", en: "2 layers of fiberglass, one unidirectional in the center for flexibility and one triaxial below throughout x 2" }, icon: "fa-check" },
         
    
        { text: { fr: "Fibre de verre triaxial x2", en: "Double triaxial fiberglass" }, icon: "fa-check" },
    */

const ski_delivery = 10; // 10

let tshirt = new Ski().assign({
          category: "textiles",
          name: "Alpine Venice",
          type: {fr:"T-shirt", en:"T-shirt"},
          uuid: 'c396c627-3c03-4cb9-ae3f-ce5fd4c8b132',
          price: 35,
          images: ["429826808_383737591035451_6382289067543893866_n","ADN-Tshirt-Blanc-Avant-V2","ADN-Tshirt-Blanc-Plie","ADN-Tshirt-Blanc-Logo"],
          description: {fr:"La collection est une édition limitée de seulement quelques dizaines de pièces disponibles. Vous retrouverez au dos du T-Shirt la topographie de la très belle ville d'Annecy, où ADN a élu domicile !", en:"The collection is a limited edition of only a few dozen pieces available. On the back of the T-shirt, you will find the topography of the beautiful city of Annecy, where DNA has made its home!"},
          composition: [{ text: { fr: "100% coton et 100% Made In France !", en: "100% cotton and 100% Made In France!" }, icon: "fa-recycle" }],
          sizes: [
            { d: 'M' },
            { d: 'L' },
            { d: 'S' },
            { d: 'XS' },
            { d: 'XL' }
          ],
          color: "blanc"
        });
let tshirtn = tshirt.copy();
tshirtn.images = ["ADN-Tshirt-Noir-Dos-V2","ADN-Tshirt-Noir-Avant-V2","ADN-Tshirt-Noir-Plie","ADN-Tshirt-Noir-Logo"],
tshirtn.uuid = 'c396c627-3c03-4cb9-ae3f-ce5fd4c8b133';
tshirtn.color = "noir";

let tourcou = new Ski().assign({
          category: "textiles",
          name: "Alpine Venice",
          type: {fr:"Cache-cou", en:"Neck-warmer"},
          uuid: 'c396c627-3c03-4cb9-ae3f-ce5fd4c8fd108',
          images: ["ADN-TDC-Duo-V2","ADN-TDC-Blanc-Details","ADN-TDC-Blanc-Droite","ADN-TDC-Blanc-Face", "ADN-TDC-Blanc-Gauche"],
          price: 25,
          description: {fr:"Nos tours de cou ont été spécialement conçus pour vous abriter du grand froid ! Edition limitée, seulement une dizaines de pièces disponibles. Vous retrouverez sur l'intégralité du tour de cou la topographie de la très belle ville d'Annecy, où ADN a élu domicile !", en:"Our neck warmers have been specially designed to shelter you from the harsh cold! Limited edition, only a dozen pieces available. You will find the topography of the beautiful city of Annecy all over the neck warmer, where DNA has made its home!"},
          composition: [{ text: { fr: "92% polyéthylène terephthalate recyclé et élasthane (8%), Made In France (100%), à Annecy même !", en: "92% recycled polyethylene terephthalate and elastane (8%), Made In France (100%), right in Annecy!" }, icon: "fa-recycle" }],
          color: "blanc"
        });
let tourcoun = tourcou.copy();
tourcoun.images = ["ADN-TDC-Noir-Details","ADN-TDC-Noir-Face", "ADN-TDC-Noir-Droite", "ADN-TDC-Noir-Gauche"];
tourcoun.uuid = 'c396c627-3c03-4cb9-ae3f-ce5fd4c8fd109';
tourcoun.color = "noir";

  export const SKIS: Ski[] = [
    new Ski().assign({
      name: "Kyle", 
      images:[ "b","p","r1","c","r2"], 
      type: {fr:"All-mountain",en:"All-mountain"}, 
      description: {fr:"Kyle, c'est le passe partout. Aussi performant sur neige damée que dans la poudreuse, il n'y a pas de quoi s'ennuyer avec lui ! Agiles, fluides, ces skis polyvalents vous procureront des sensations diverses et variées sans jamais vous lasser !", en:""},
      note: FIXS,
      mark: "THE ORIGINAL",
      category: 'skis',
      delivery: ski_delivery,
      price: 630, 
      sizes: [
        { d: 169, s: 122, p: 84, t: 105, r: 'R15', po: 1630 },
        { d: 177, s: 123, p: 84, t: 106, r: 'R16', po: 1840 },
        { d: 185, s: 124, p: 84, t: 107, r: 'R18', po: 1920 }
      ], 
      rating: 4.2, 
      amount: 1, 
      uuid: '6622047e-f9dd-4583-9e81-941b84f851c4',
      colors: [{ color: '#c0e5b9', colors: ['#52b63a', '#a2e156', '#f0ea48'] }], 
      bgcolor: '127,200,68',
      scores: [80, 77, 86, 63, 100, 73],
      impacts: impact, // Remplacez par les informations d'impact réelles
      categoriesScores: [],
      score: { y: 'A', color: '#2ecc71' },
      stats: { distance: 3000, recycled: 2, maxSpeed: 100, stations: 20, pays: 3, utilisateurs: 4 },
      levels: [1, 2, 3],
      types: [1],
      composition: [SEM_REB, CAR_ACIER, ABS_CHAMP, NOYAU_PEU, RENF_ALU, FIB_VER, TOP_REC_BIO],
      is360: true
    }),
    new Ski().assign({
      name: "Kody", images: ["b","p","r1","c","r2"], type: {fr:"Ski alpin", en:"Alpine ski"}, 
      description: {fr:"Léger comme une plume, le ski Alpin Kody vous permettra d'atteindre la vitesse recherchée en un rien de temps. Prenez une grande inspiration et laissez vous porter, ca va glisser !",en:"Light as a feather, the Kody Alpine ski will allow you to reach the desired speed in no time. Take a deep breath and let yourself go, it's going to glide!"},
      note: FIXS,
      delivery: ski_delivery,
      category: 'skis',
      mark: "THE ORIGINAL",
      price: 630, sizes: [
        { d: 155, s: 120, p: 72, t: 105, r: 'R13', po: 1470 },
        { d: 162, s: 120, p: 72, t: 105, r: 'R14', po: 1550 },
        { d: 169, s: 121, p: 72, t: 106, r: 'R15', po: 1630 }
      ], rating: 4.8, amount: 0, 
      uuid: 'ad9b981c-19ea-45bc-bba9-19344ac0efa1',
      colors: [{ color: '#abb9ce', colors: ['#1b3e72', '#b6c3d4', '#f5eb4d'] }], bgcolor: '219,235,64',
      scores: [83, 83, 85, 73, 91, 84],
      impacts: impact, // Remplacez par les informations d'impact réelles
      categoriesScores: [],
      score: { y: 'A', color: '#2ecc71' },
      stats: { distance: 3000, recycled: 2, maxSpeed: 100, stations: 20, pays: 3, utilisateurs: 4 },
      levels: [1, 2],
      types: [2],
      composition: [SEM_REB, CAR_ACIER, ABS_CHAMP, NOYAU_PEU_POLY, RENF_ALU, FIB_VER, TOP_REC_BIO],
      is360: true
    }),
    new Ski().assign({
      name: "Kay", 
      category: 'skis',
      delivery: ski_delivery,
      mark: "THE ORIGINAL",
      images: [ "b","p","r1","c","r2"], type: {fr:"Ski de randonnée", en:"Touring ski"}, description: {fr:"Passionnés de la montagne et du grand air, ce ski est pour vous ! Kay vous emmenera dans les sentiers qui n'ont jamais été encore foulés. 86 mm au patin, ce ski est dédié aux skieurs intermédiaires à confirmés.",en:""},
      note: {fr:"Ne sont pas fournis avec des fixations.",en:"Do not come with bindings."},
      price: 580, sizes: [
        { d: 164, s: 118, p: 84, t: 104, r: 'R18', po: 1175 },
        { d: 172, s: 120, p: 84, t: 106, r: 'R18', po: 1230 },
        { d: 180, s: 122, p: 84, t: 108, r: 'R19', po: 1350 }
      ], rating: 4.5, amount: 2, 
      uuid: 'c396c627-3c03-4cb9-ae3f-ce5fd4c8b108',
      colors: [{ color: '#b5b4ca', colors: ['#333365', '#3591c1', '#5ac8e1'] }], bgcolor: '102,85,140',
      scores: [69, 77, 82, 59, 50, 77],
      impacts: impact, // Remplacez par les informations d'impact réelles
      categoriesScores: [],
      score: { y: 'B', color: '#1abc9c' },
      stats: { distance: 3000, recycled: 2, maxSpeed: 100, stations: 20, pays: 3, utilisateurs: 4 },
      levels: [2, 3],
      types: [3],
      composition: [SEM_REB, CAR_ACIER, ABS_CHAMP, NOYAU_PEU,  FIB_VER, TOP_REC_BIO],
      is360: true
    }),
    /*tshirt,
    tshirtn,*/
    tourcou,
    tourcoun
  ];
  

export const DefaultSki: Ski = new Ski().assign({name:"", images:[], type:{fr:"",en:""}, description:{fr:"",en:""}, note:{fr:"",en:""}, levels:[], types:[],
price:0, sizes:[], rating:5, amount:0, uuid:"", composition: [], colors:[], bgcolor:"", scores:[], impacts:[],categoriesScores:[],score:{y:'', color:'#FFF'},stats:{distance:0, recycled:0, maxSpeed:0, stations:0, pays:0, utilisateurs:0}});

SKIS.forEach(S=>
    {
        S.categoriesScores = [
            {title:'Emissions', y: 0, icon:'production3', unit: 'kg CO2e', letter:'A', color:'#2ecc71',p:0},
            {title:'Transport', y: 0, icon:'transport', unit: 'kg CO2e',p:0},
            {title:'Conso d\'eau', y: 0, icon:'water', unit: 'kg',p:0},
            {title:'Emballage', y: 0, icon:'emb2', unit:'g',p:0},
            {title:'Energie', y: 0, icon:'enrgy', unit: 'MJ',p:0},
            {title:'Recyclage', y: 0, icon:'recy', unit: '%',p:0},
          ];
          S.impacts.map((s,i) => {S.categoriesScores[i].y = s.v;S.categoriesScores[i].p = s.p});
      
          S.categoriesScores.forEach((c,i) => {
            let color = "#2ecc71"; let letter = 'A';
            
            if (c.p < 10) {color = "#e74c3c"; letter='F'}
            else if (c.p < 30) {color = "#e67e22"; letter='E'}
            else if (c.p < 50) {color = "#f1c40f"; letter='D'}
            else if (c.p < 70) {color = "#34495e"; letter='C'}
            else if (c.p < 90) {color = "#1abc9c"; letter='B'};
      
            c.letter=letter;
            c.color=color;
          })
    });