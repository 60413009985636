

import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';


@Component({
    selector: 'adn-progress-bar',
    templateUrl: './steps-progress-bar.component.html',
    styleUrls: ['./steps-progress-bar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ADNStepsProgressBarComponent implements OnInit {
    @Input() progress: number = 0;
    @Input() title: string;
    @Input() name: string;
    @Input() desc: string;

    @Input() block: string = '';

    constructor(

    ) {

    }

    ngOnInit(): void {

    }

}
