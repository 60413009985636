import { Component, Input } from '@angular/core';

@Component({
  selector: 'hl',
  templateUrl: './highlight.component.html',
  styleUrls: ['./highlight.component.scss']
})
export class HighlightTextComponent {
  @Input() text: string;
}
