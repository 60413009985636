// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.name {
  font-size: 16px;
  font-family: "medium";
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 5px 0px;
}

.detail {
  font-size: 14px;
}

.price {
  color: var(--ion-color-primary);
  font-size: 16px;
}

p {
  width: 100%;
  align-content: center;
  text-align: center;
  margin-top: 5%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modals/cart/cart.modal.scss"],"names":[],"mappings":"AACA;EACE,eAAA;EACA,qBAAA;EACA,yBAAA;EACA,mBAAA;EACA,gBAAA;AAAF;;AAEA;EACE,eAAA;AACF;;AACA;EACE,+BAAA;EACA,eAAA;AAEF;;AAEA;EACE,WAAA;EACA,qBAAA;EACA,kBAAA;EACA,cAAA;AACF","sourcesContent":["\n.name {\n  font-size: 16px;\n  font-family: \"medium\";\n  text-transform: uppercase;\n  letter-spacing: 1px;\n  padding: 5px 0px;\n}\n.detail {\n  font-size: 14px;\n}\n.price {\n  color: var(--ion-color-primary);\n  font-size: 16px;\n}\n\n\np {\n  width: 100%;\n  align-content: center;\n  text-align: center;\n  margin-top: 5%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
