// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2, h4, .edges, p {
  color: black !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modals/promo/promo.modal.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;AACJ","sourcesContent":["h2, h4, .edges, p {\n    color:black !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
