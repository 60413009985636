

import { AfterViewInit, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';

import { UtilService } from '@services/util.service';
import { HttpClient } from '@angular/common/http';
import { ModalController } from '@ionic/angular';
import { BaseViewI } from '@alphaa/models/ionic/baseViewI.model';
import { ICoreService } from '@alphaa/services/ionic/icore.service';

@Component({
    selector: 'adn-hlist',
    templateUrl: './hlist.component.html',
    styleUrls: ['./hlist.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HlistComponent extends BaseViewI implements OnInit, OnChanges  {
    @Input() elements: any;
    @Input() intro: string;

    block: string = '';

    constructor(public core: ICoreService, public util:UtilService, public http: HttpClient, private modalCtrl: ModalController) {
        super(core);
    }

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {

    }

}
