import { environment } from '@envs/environment';

export const SUPER_USER = 'SUPER_USER';

// SETTINGS SNACKBAR
export const SNACKBAR_DURATION_SUCCESS = 5000;
export const SNACKBAR_DURATION_FAILED = 60000;
export const SNACKBAR_POSITION = "top";
export const SNACKBAR_CLASS_SUCCESS = "custom-mat-snackbar-success";
export const SNACKBAR_CLASS_WARNING = "custom-mat-snackbar-warning";
export const SNACKBAR_CLASS_ERROR = "custom-mat-snackbar-error";

// COMMON
export const ACCESS_TOKEN = `alpha_access_token`;
export const REFRESH_TOKEN = `alpha_refresh_token`;
export const TOKEN_TYPE = `alpha_token_type`;
export const CURRENT_USER = 'current_user';
export const SUCCESS = 'success';
export const USER_STORAGE = 'currentUser';
export const USER_TOKEN_STORAGE = 'currentUserToken';
export const SAVE_USER_SESSION = 'saveUserSession';

// TYPE ACTION
export const VIEW = "view";
export const ADD = "add";
export const EDIT = "edit";
export const COPY = "copy";

// USER MANAGEMENT
export const USER = "user";
export const APPLICATION = "application";
export const PERMISSION = "permission";
export const ROLE = "role";
export const ROLE_USERS = "role_users";

// FILTER TYPES
export const FILTER_SEARCH = "search";
export const FILTER_SELECT = "select";
export const FILTER_DATE = "date";

// CONSTRAINT
export const MAX_LENGTH_APPLICATION_NAME = 20;
export const MAX_LENGTH_APPLICATION_DESCRIPTION = 255;

export const MAX_LENGTH_ROLE_NAME = 200;
export const MAX_LENGTH_ROLE_DESCRIPTION = 1000;

export const MAX_LENGTH_PERMISSION_KEY = 200;
export const MAX_LENGTH_PERMISSION_DESCRIPTION = 1000;

//SESSION_MODE
export enum SESSION_MODE {
  LOCAL = "local", //User Session saved in session storage
  SAVED = "saved", //User session saved in local storage
  SHARED = "shared", //User session saved in cookies
}
