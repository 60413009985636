export const PARAMETERS = {
    website:'adnskis.com', 
    name:'ADN SKIS',
    team:'ADN GROUP',
    compagny: 'ADN GROUP',
    email: 'contact@adnskis.com',
    emailSupport: 'contact@adnskis.com',
    dataStored: 'European Economic Area (EEA)',
    updateDate: '30/08/2023',
    capital: '1020,44€',
    siegeSocialAdress: 'Parc des Glaisins, 12 C Rue du Pré Faucon, 74940 Annecy',
    societeAdress: '479 chemin de crêt tagnoux, les granges 74550 Orsier',
    immatriculation: '914916432',
    TVA: 'FR60914916432',
    publicationDirector: 'Camille Lambert',
    publicationDirectorFonction: 'Présidente',
    hebergeur: 'OVH',
    hebergeurAdress: '2 rue kellermann - BP80157 59053 Roubaix Cedex 1',
    legalConception: 'ADN GROUP',
    creditsPhotos: 'Jémérie Pontin & Corentin Croisonnier & Mon shooting & Mathis Decroux',
    transportCO2: '27%',
    facebook: 'https://www.facebook.com/adnskis/'
  };