import { Component, Input, OnInit, ViewChild } from "@angular/core";

import * as HighCharts from 'highcharts';
import more from 'highcharts/highcharts-more';
import HighChartsbullet from 'highcharts/modules/bullet';
import StreamgraphModule  from 'highcharts/modules/streamgraph';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';

more(HighCharts);
HighChartsbullet(HighCharts);
StreamgraphModule(HighCharts);
HighchartsSolidGauge(HighCharts)

@Component({
  selector: 'radar',
  templateUrl: './radar.html',
  styleUrls: ['./radar.scss'],
})
export class RadarChartComponent implements OnInit {
    @Input() ski;
    @Input() feedbacks;
  
    constructor() {}

    ngOnInit(): void {
        if(this.ski) {
            var dataName = function(c) {
            return '<span style="display:flex;flex-direction:column;text-align:center;font-size:0.9em;"><img src="/assets/icon/' + c.icon + '.png" style="margin-left:auto;margin-right:auto;width: 20px; height: 20px;"/><b>'+c.title+'</b></span>';
            };

            this.ski.scores.map((s,i) => this.feedbacks[i].y = s);

            let data = this.feedbacks.map(c => [c.y, dataName(c)]);

            let round25 = this.feedbacks.map(c => [25]);
            let round50 = this.feedbacks.map(c => [50]);
            let round75 = this.feedbacks.map(c => [75]);
            let round100 = this.feedbacks.map(c => [100]);

            // @ts-ignore
            HighCharts.chart('container', {
            title: {
                text:''
            },
            credits:{enabled: false},
            chart: {
                width: 400, // Largeur fixe en pixels
                height: 300,
                polar: true,
                type: 'area',
                events: {
                    render: function() {
                        var ticks = this.xAxis[0].ticks,
                            length = this.xAxis[0].categories.length,
                            rotation = 360 / length / 2;

                        HighCharts.objectEach(ticks, function(tick) {
                            tick.label.attr({
                                rotation: rotation
                            });

                            rotation += 360 / length;
                        });
                    }
                },
                marginBottom:30,
                marginTop:40
            },  
            pane: {
                startAngle: 0,
                endAngle: 360,
            },
            
            legend: {
                enabled: false
            },
            xAxis: {
                gridLineWidth: 1,
                lineWidth: 1,
                type: 'category',
                tickmarkPlacement: 'on',
                labels: {
                useHTML: true,
                align: 'center'
                },
                offset:-40
            },
            yAxis: {visible:false,min:0, max:101,
                tickPositioner: function() {
                var positions = [],
                    ext = this.getExtremes(),
                    xMax = Math.round(ext.max),
                    xMin = Math.round(ext.min);
            
                for (var i = xMin; i < xMax; i++) {
                    if (i % 50 == 0) {
                    positions.push(i);
                    }
                }
                positions.push(xMax);
                return positions;
                }
            },
            plotOptions: {
                series: {
                    states: {
                    hover: {
                        enabled: false
                    }
                    },
                    dataLabels: {
                    format: '<span style="background-color:white;border-radius:100%;padding:60%;font-size:0.7em;font-weight:bold;">{y}</span>',
                    enabled:true,
                    useHTML: true,
                    align: 'center',
                    x: -10,
                    y:-10,
                    verticalAlign: 'top'
                    },
                    marker: {
                    enabled:false
                    }
                }
            },
            series: [
                {
                keys: ['y'],
                data: round25,
                type:'areaspline',
                dataLabels: {enabled:false},
                color: 'rgba(19,19,19,0.8)',
                enableMouseTracking: false,
                },
                {
                keys: ['y'],
                data: round50,
                type:'areaspline',
                dataLabels: {enabled:false},
                color: 'rgba(72,72,72,0.1)',
                enableMouseTracking: false,
                },
                {
                keys: ['y'],
                data: round75,
                type:'areaspline',
                dataLabels: {enabled:false},
                color: 'rgba(223,223,223,0.1)',
                enableMouseTracking: false,
                },
                {
                keys: ['y'],
                data: round100,
                type:'areaspline',
                dataLabels: {enabled:false},
                color: 'rgba(230,230,230,0.1)',
                enableMouseTracking: false,
                },
                {
                keys: ['y', 'name'],
                data: data,
                pointPlacement: 'on',
                color: 'rgba('+this.ski.bgcolor+',0.1)',
                enableMouseTracking: false,
                },
                {
                keys: ['y', 'name'],
                data: data,
                type: 'line',
                pointPlacement: 'on',
                color: 'rgba('+this.ski.bgcolor+',1)',
                enableMouseTracking: false,
                },
                {
                keys: ['y', 'name'],
                data: [80, 85, 80, 60, 95, 70],
                type: 'line',
                pointPlacement: 'on',
                color: 'rgba(0,0,0,1)',
                enableMouseTracking: false,
                }
            ]
            });
        }
    }
}