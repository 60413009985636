

import { AfterViewInit, Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Ski } from '@models/skis';

@Component({
    selector: 'adn-caracs',
    templateUrl: './caracs.component.html',
    styleUrls: ['./caracs.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CaracsComponent implements OnInit  {
    @Input() ski: Ski;
    @Input() element: number;

    constructor(

    ) {

    }

    ngOnInit() {}


    ionViewDidEnter() {
        
    }

    
}
