import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CONTACT_API, CONTACT_LIST_API, ORDER_TEMPLATE, SMTP_EMAIL, TEST_MAIL_TEMPLATE } from '@constants/brevo';
import {PurchaseParams} from '@models/brevo';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
    apiKey = environment.brevoApiKey;
  constructor(private http: HttpClient) {}

  sendOrderConfirmationEmail(userEmail: string, userName: string, purchase: PurchaseParams) {
    const url = SMTP_EMAIL; // URL de l'API Brevo pour l'envoi d'email
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'api-key': this.apiKey,
    });

    if (!environment.production) console.log('purchase', purchase);

    const emailData = {
        to: [{"email":userEmail,"name":userName}],
        templateId: ORDER_TEMPLATE,
        params: purchase,  
    };
    return this.http.post(url, emailData, { headers });
  }

  sendTestEmail(userEmail: string, userName: string, orderId: string) {
    const url = SMTP_EMAIL; // URL de l'API Brevo pour l'envoi d'email
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'api-key': this.apiKey,
    });

    const emailData = {
        to: [{"email":userEmail,"name":userName}],
        templateId: TEST_MAIL_TEMPLATE,
        params: { 
          TEST:userName
        },  
    };
    return this.http.post(url, emailData, { headers });
  }

  addContactList(email: string, id: number) {
    const url = CONTACT_LIST_API.replace('listId', ''+id);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'api-key': this.apiKey,
    });

    const body = {
      emails: [email]
    };

    return this.http.post(url, body, { headers });
  }

  addContact(email: string, listIds: number[], attributes: any) {
    const url = CONTACT_API;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'api-key': this.apiKey,
    });

    const body = {
      email: email,
      listIds: listIds,
      attributes: attributes,
      emailBlacklisted: false,
      smsBlacklisted: false
    };

    return this.http.post(url, body, { headers });
  }
}
