

import { AfterContentInit, AfterViewInit, Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';

import {v4 as uuidv4} from 'uuid';
import { UtilService } from '../../services/util.service';

@Component({
    selector: 'video-float-adn',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VideoFloatComponent implements OnInit, AfterContentInit  {
    @Input() img: string = "";
    @Input() video: string = "";
    @Input() text: string = "Regarder la video";

    @Input() top: string = "0";
    @Input() autoplay: boolean = true;

    uuid:string = uuidv4();
    expanded:boolean = false;

    playing:boolean = false;

    constructor(
        public util: UtilService
    ) {

    }

    ngOnInit() {
        this.playing = this.img == '';
    }

    ngAfterContentInit(): void {
        var myVideo: any = document.getElementById(this.uuid);
        if(myVideo) myVideo.muted = true; 
    }

    ionViewDidEnter() {
        
    }

    isPaused(id:string) {
        var myVideo: any = document.getElementById(id);
        return myVideo.paused;
    }

    playPause(id:string) {
        
        var header: any = document.getElementById('header');
        var wrapper: any = document.getElementById(id+'-wrapper');
        var myVideo: any = document.getElementById(id);
        if (myVideo.paused) {
            header.classList.remove('stopped');
            wrapper.classList.add('playing');
            header.classList.remove('hide');
            myVideo.classList.remove('video-poster');
            myVideo.play();
            this.playing = true;
        }
        else {
            header.classList.add('stopped');
            wrapper.classList.remove('playing');
            header.classList.add('hide');
            myVideo.classList.add('video-poster');
            myVideo.pause();
            this.playing = false;
        } 
    }

    expand() {
        const videos = document.querySelectorAll('.video-play');
        const images = document.querySelectorAll('.img-video');

        var header: any = document.getElementById('header');
        var wrapper: any = document.getElementById(this.uuid+'-wrapper');
        var myVideo: any = document.getElementById(this.uuid);

        if(!myVideo) return;

        if (!this.expanded) {
            videos.forEach(v => {
                if (v.id != this.uuid+ '-wrapper') v.classList.add('hide');
            })

            wrapper.classList.remove('stopped');
            wrapper.classList.add('playing');
            header.classList.add('hide');
            myVideo.currentTime = 0;
            myVideo.muted = false; 
            myVideo.play();
            this.playing = true;

            images.forEach( i => i.classList.add('hide'));
        }
        else {
            videos.forEach(v => {
                if (v.id  != this.uuid+ '-wrapper') v.classList.remove('hide');
            })

            wrapper.classList.add('stopped');
            wrapper.classList.remove('playing');
            header.classList.remove('hide');
            myVideo.muted = true; 
            this.playing = false;
            //myVideo.pause();

            images.forEach( i => i.classList.remove('hide'));
        }
        this.expanded = !this.expanded;
      }
}
