import { ApiAnswer } from '@alphaa/models/apiAnswer.model';
import { DynView } from '@alphaa/models/dyn.model';
import { RequestTracker } from '@alphaa/utils/request-tracker.utils';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoggerService } from './logger.service';

function filterUndefined(params: any, strict: boolean = true) {
    var result = {};
    for (var key in params) {
        if (strict ? params[key] !== undefined : params[key] != undefined) result[key] = params[key];
    }
    return result;
}

@Injectable({
    providedIn: 'root',
})
export class HttpService extends DynView {
    headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
    });

    constructor(private http: HttpClient, private readonly _loggerService: LoggerService, private readonly _spinner: NgxSpinnerService) {
        super();

        RequestTracker.getValue().subscribe(value => {
            value == 0 ? this._spinner.hide() : this._spinner.show();
        });
    }

    isUp() {
        this.get('status').pipe(
            map(answer => {
                return answer.valid;
            }),
        );
    }

    getApiUrl(url, local?: Boolean) {
        if (!url || url.indexOf('http') !== -1) return url;
        let apiUrl = local ? '' : environment.apiUrl;
        if (url.substring(0, 2) == './') {
            return url;
        }

        if (apiUrl.slice(-1) != '/') {
            apiUrl += '/';
        }
        if (url.substring(0, 1) == '/') {
            url = url.substring(1);
        }
        return apiUrl + url;
    }

    convertToHttpParams(params: {}) {
        let httpParams = new HttpParams();

        if (params) {
            Object.entries(params).forEach(element => {
                httpParams = httpParams.set(element[0], String(element[1]));
            });
        }

        return httpParams;
    }

    @RequestTracker.Track()
    get(
        url: string,
        params?: HttpParams | { [param: string]: string | string[] | number | boolean },
        _silent: boolean = false,
        local: Boolean = false,
        strict: boolean = true,
    ): Observable<ApiAnswer> {
        params = filterUndefined(params, strict);
        let complete_url = local ? url : this.getApiUrl(url, local);

        if (!this.isProd) {
            console.log('SEND GET ' + complete_url + ' :', params);
        }
        const method = 'GET';
        return this.http
            .get<HttpResponse<any>>(complete_url, {
                params: params,
                observe: 'response',
            })
            .pipe(
                map(data => {
                    return this.checkAnswer(url, params, data, method);
                }),
                catchError(err => this._errorHandler(url, params, err, method)),
            );
    }

    @RequestTracker.Track()
    post(url: string, body: any, params?: HttpParams | { [param: string]: string | string[] }, options?: {}, _silent: boolean = false): Observable<ApiAnswer> {
        let localOptions = options ?? {
            headers: this.headers,
            observe: 'response' as 'response',
            params: params,
        };
        if (body instanceof FormData) {
            localOptions = undefined;
        }

        if (!this.isProd) {
            console.log('SEND POST ' + this.getApiUrl(url, false) + ' :', body, params);
        }
        const method = 'POST';
        return this.http.post<HttpResponse<any>>(this.getApiUrl(url, false), body, localOptions).pipe(
            map(data => {
                return this.checkAnswer(url, params, data, method);
            }),
            catchError(err => this._errorHandler(url, params, err, method)),
        );
    }

    @RequestTracker.Track()
    put(url: string, body: any, params?: HttpParams | { [param: string]: string | string[] }, _silent: boolean = false): Observable<ApiAnswer> {
        let options = {
            headers: this.headers,
            params: params,
            observe: 'response' as 'response',
        };
        if (!this.isProd) {
            console.log('SEND PUT ' + this.getApiUrl(url, false) + ' :', params);
        }
        const method = 'PUT';
        return this.http.put<HttpResponse<any>>(this.getApiUrl(url, false), body, options).pipe(
            map(data => {
                return this.checkAnswer(url, params, data, method);
            }),
            catchError(err => this._errorHandler(url, params, err, method)),
        );
    }

    @RequestTracker.Track()
    patch(url: string, body: any, params?: HttpParams | { [param: string]: string | string[] }, _silent: boolean = false): Observable<ApiAnswer> {
        let options = {
            headers: this.headers,
            params: params,
            observe: 'response' as 'response',
        };
        if (!this.isProd) {
            console.log('SEND PATCH ' + this.getApiUrl(url, false) + ' :', params);
        }
        const method = 'PATCH';
        return this.http.patch<HttpResponse<any>>(this.getApiUrl(url, false), body, options).pipe(
            map(data => {
                return this.checkAnswer(url, params, data, method);
            }),
            catchError(err => this._errorHandler(url, params, err, method)),
        );
    }

    @RequestTracker.Track()
    delete(url: string, body?: any | {}, params?: HttpParams | { [param: string]: string | string[] }, _silent: boolean = false): Observable<ApiAnswer> {
        let options = {
            headers: this.headers,
            body: body ?? {},
            params: params,
            observe: 'response' as 'response',
        };
        if (!this.isProd) {
            console.log('SEND DELETE ' + this.getApiUrl(url, false) + ' :', body, params);
        }
        const method = 'DELETE';
        return this.http.delete<HttpResponse<any>>(this.getApiUrl(url, false), options).pipe(
            map(data => {
                return this.checkAnswer(url, params, data, method);
            }),
            catchError(err => this._errorHandler(url, params, err, method)),
        );
    }

    public getUpload(formData, url?: string) {
        if (!url) url = 'files/upload';

        return this.http.post<any>(this.getApiUrl(url), formData, {
            reportProgress: true,
            observe: 'events',
        });
    }

    /**
     * Handler for http error
     * @param error
     */
    private _errorHandler(url: string, params: any, error: HttpErrorResponse | any, method = 'GET', raise: boolean = false): Observable<any> {
        let errorMessage: string;

        if (error instanceof HttpErrorResponse) {
            if (error.error instanceof ErrorEvent) {
                errorMessage = `Error: ${error.error.message}`;
            } else {
                const ans = this.checkAnswer(url, params, error, method);
                errorMessage = `Error Code: ${error.status}\nMessage: ${ans.status_description}`;
                if (!raise) {
                    this._loggerService.error(errorMessage);
                    return of(ans);
                }
            }
        } else {
            errorMessage = error.message ? error.message : error.toString();
        }
        this._loggerService.error(errorMessage);

        throw new Error(errorMessage || 'Server Error');
    }

    checkAnswer(url: string, params: any, data, method = 'GET'): ApiAnswer {
        if (!this.isProd) {
            console.log('ANSW ' + method + ': ', data);
        }
        let answer = new ApiAnswer(url, params, data);
        return answer;
    }
}
