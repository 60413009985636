

import { AfterViewInit, Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Ski } from '@models/skis';

@Component({
    selector: 'adn-impact',
    templateUrl: './impact.component.html',
    styleUrls: ['./impact.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ImpactComponent implements OnInit  {
    @Input() ski: Ski;

    constructor(

    ) {

    }

    ngOnInit() {}


    ionViewDidEnter() {
        
    }

    
}
