import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { FooterComponent } from '@components/footer/footer.component';
import { HMenuComponent } from '@components/hmenu/hmenu.component';
import { FabsComponent } from '@components/fabs/fabs.component';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ICoreService } from '@alphaa/services/ionic/icore.service';
import { NotificationService } from '@alphaa/services/ionic/notification.service';
import { VideoComponent } from './video/video.component';
import { ImageTitledComponent } from './imaged-titled/image-titled.component';
import { ADNStepsProgressBarComponent } from './progressbar/steps-progress-bar.component';
import { DimentionsComponent } from './dimentions/dimentions.component';
import { CaracsComponent } from './caracs/caracs';
import { HeadComponent } from './head/head';
import { HighlightTextComponent } from './style/highlight/highlight.component';
import { VideoFloatComponent } from './video-float/video.component';

import { RadarChartComponent } from './perfs/radar';
import { NgApexchartsModule } from "ng-apexcharts";
import { ImpactComponent } from './impact/impact';
import { TranslateModule } from '@ngx-translate/core';
import { RatingComponent } from './rating/rating';
import { HlistComponent } from './hlist/hlist';
import { IonicModule } from '@ionic/angular';
import { PromoModal } from './modals/promo/promo.modal';


@NgModule({
  imports: [
    CommonModule, 
    RouterModule, 
    IonicModule,
    HttpClientModule, 
    FormsModule,
    ReactiveFormsModule, 
    NgApexchartsModule, 
    TranslateModule ],
  declarations: [
    DimentionsComponent, 
    ImageTitledComponent, 
    VideoComponent, 
    VideoFloatComponent,
    FooterComponent, 
    HMenuComponent, 
    FabsComponent, 
    ADNStepsProgressBarComponent,
    CaracsComponent,
    ImpactComponent,
    HeadComponent,
    HlistComponent,
    RatingComponent,
    HighlightTextComponent,
    RadarChartComponent,
    PromoModal
  ],
  exports: [
    DimentionsComponent, 
    ImageTitledComponent, 
    VideoComponent, 
    VideoFloatComponent,
    FooterComponent, 
    HMenuComponent, 
    FabsComponent, 
    ADNStepsProgressBarComponent,
    CaracsComponent,
    ImpactComponent,
    HeadComponent,
    HlistComponent,
    RatingComponent,
    HighlightTextComponent,
    RadarChartComponent,
    PromoModal
  ],
  providers: [ICoreService, NotificationService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ADNCommonModule { }