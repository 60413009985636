import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ADNView } from '@models/view';
import { UtilService } from '@services/util.service';

import { BaseView } from '../../../alphaa/models/views.model';

@Component({
    selector: 'adn-fabs',
    templateUrl: './fabs.component.html',
    styleUrls: ['./fabs.component.scss'],
    encapsulation: ViewEncapsulation.None
  })
export class FabsComponent extends BaseView {
  
    constructor(public util: UtilService) { 
      super();
    }
}