
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, isDevMode } from '@angular/core';

import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';
import { ADNCommonModule } from '@components/adn-common.module';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ICoreService } from '@alphaa/services/ionic/icore.service';

import { NgxImageZoomModule } from 'ngx-image-zoom';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule  } from 'ngx-google-analytics';
import { environment } from '@envs/environment';

import { ErrorInterceptor } from '@alphaa/interceptors/error.interceptor';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { JwtModule } from '@auth0/angular-jwt';
import { NgxPayPalModule } from 'ngx-paypal';

export const interceptorProviders = [
  //{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function tokenGetter(): string {
  return sessionStorage.getItem("access_token");
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    NgxImageZoomModule,
    BrowserModule,
    CommonModule,  
    JwtModule.forRoot({
      config: {
        tokenGetter,
      },
    }),
    IonicModule.forRoot(), 
    AppRoutingModule, 
    FontAwesomeModule, 
    ADNCommonModule, BrowserAnimationsModule, ReactiveFormsModule,
    NgxSpinnerModule,
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    NgxGoogleAnalyticsRouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxPayPalModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, ICoreService, interceptorProviders],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
