// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-container {
  position: relative;
}

.chart-title {
  position: absolute;
  transform: rotate(-90deg);
  text-transform: uppercase;
  transform-origin: left;
  left: -20px;
  top: 50%;
  white-space: nowrap;
}

#chart {
  /* Vos styles pour le graphique ici */
}`, "",{"version":3,"sources":["webpack://./src/app/components/perfs/radar.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEE;EACE,kBAAA;EACA,yBAAA;EACA,yBAAA;EACA,sBAAA;EACA,WAAA;EACA,QAAA;EACA,mBAAA;AACJ;;AAEE;EACE,qCAAA;AACJ","sourcesContent":[".chart-container {\n    position: relative;\n  }\n  \n  .chart-title {\n    position: absolute;\n    transform: rotate(-90deg);\n    text-transform: uppercase;\n    transform-origin: left;\n    left: -20px;\n    top: 50%;\n    white-space: nowrap;\n  }\n  \n  #chart {\n    /* Vos styles pour le graphique ici */\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
