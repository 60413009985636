

import { AfterViewInit, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Ski } from '@models/skis';
import { UtilService } from '@services/util.service';
import { HttpClient } from '@angular/common/http';

import { ModalController } from '@ionic/angular';
import { environment } from '@envs/environment';

@Component({
    selector: 'adn-head',
    templateUrl: './head.component.html',
    styleUrls: ['./head.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HeadComponent implements OnInit, OnChanges  {
    @Input() ski: Ski;
    @Input() number: number;

    @Input() position: string = 'center';

    @Input() price:boolean = false;

    average:number;

    block:string = '';

    @Output() openRatingsEvent = new EventEmitter<void>();

    constructor(public util:UtilService, public http: HttpClient, private modalCtrl: ModalController) {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.ski && this.ski && this.ski.name) {
            this.fetchAverageRating();
        }
    }

    async openRatings() {
        this.openRatingsEvent.emit();
        /*const modal = await this.modalCtrl.create({
          component: RatingModal,
          componentProps: {'ski':this.ski, 'rating':this.average}
        });
        modal.present();*/
      }

    private fetchAverageRating() {
        this.http.get<any>(environment.apiUrl + '/products/tests/average?name=' + this.ski.name)
            .subscribe(response => {
                if (response.average_rating) {
                    this.average = response.average_rating
                }
            });
    }
}
