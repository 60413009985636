// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.menu-links a:after {
  content: " ";
  padding-left: 0.5em;
  font-size: 1.2em;
}
.menu-links a:hover {
  color: black;
}
.menu-links a:hover:after {
  content: "🢒";
  padding-left: 0.5em;
  font-size: 1.2em;
}`, "",{"version":3,"sources":["webpack://./src/app/components/fabs/fabs.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAEV;EACE,YAAA;EACA,mBAAA;EACA,gBAAA;AAAR;AAII;EACE,YAAA;AAFN;AAIM;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AAFR","sourcesContent":[".menu-links {\n    a {\n      &:after {\n        content: ' ';\n        padding-left: 0.5em;\n        font-size:1.2em;\n      }\n    }\n  \n    a:hover {\n      color: black;\n  \n      &:after {\n        content: '\\1F892';\n        padding-left: 0.5em;\n        font-size:1.2em;\n      }\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
