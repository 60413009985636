// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.properties {
  text-align: center;
  transition: transform 0.5s ease;
}
.properties i {
  margin: 4px;
  flex: 1;
  border-top: 6px solid rgba(0, 0, 0, 0.127);
  min-width: max-content;
  font-size: 10px;
  overflow: hidden;
  white-space: nowrap;
}
.properties .selected {
  border-top: 6px solid black;
}
.properties .selectors {
  padding-top: 10px;
  padding-bottom: 10px;
}
.properties .selectors h3 {
  text-align-last: start;
  text-transform: uppercase;
}

.selections {
  display: flex;
  flex-direction: row;
}
.selections i {
  min-width: 60px;
}

h3 {
  margin-top: 0;
  font-size: small;
}`, "",{"version":3,"sources":["webpack://./src/app/components/caracs/caracs.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EAEA,+BAAA;AAAF;AAEE;EACE,WAAA;EACA,OAAA;EACA,0CAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,2BAAA;AADJ;AAIE;EACE,iBAAA;EACA,oBAAA;AAFJ;AAII;EACE,sBAAA;EACA,yBAAA;AAFN;;AAOA;EACE,aAAA;EACA,mBAAA;AAJF;AAME;EACE,eAAA;AAJJ;;AAOA;EACE,aAAA;EACA,gBAAA;AAJF","sourcesContent":[".properties {\n  text-align: center;\n\n  transition: transform .5s ease;\n\n  i {\n    margin:4px;\n    flex: 1;\n    border-top: 6px solid rgba(0, 0, 0, 0.127);\n    min-width: max-content;\n    font-size:10px;\n    overflow: hidden;  \n    white-space: nowrap;\n  }\n\n  .selected {\n    border-top: 6px solid black;\n  }\n\n  .selectors {\n    padding-top:10px;\n    padding-bottom:10px;\n\n    h3 {\n      text-align-last: start;\n      text-transform: uppercase;\n    }\n  }\n}\n\n.selections {\n  display: flex;\n  flex-direction: row;\n\n  i {\n    min-width:60px;\n  }\n}\nh3 {\n  margin-top: 0;\n  font-size:small;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
