import { ACCESS_TOKEN, REFRESH_TOKEN, SAVE_USER_SESSION, TOKEN_TYPE, USER_STORAGE } from '@alphaa/constants/constants';
import { Token } from '@alphaa/models/auth/token';
import { DynClass } from '@alphaa/models/dyn.model';
import { User, UserToken } from '@alphaa/models/user.model';
import { Injectable } from '@angular/core';
import { environment } from '@envs/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class UserTokenService extends DynClass {
    private _DOMAIN = environment.domain;
    private _EXP = 365;
    private _PATH = '/';

    constructor(private readonly _cookieService: CookieService) {
        super();
    }

    public get accessToken() {
        return `${environment.stage}_${ACCESS_TOKEN}`
    }

    public get refreshToken() {
        return `${environment.stage}_${REFRESH_TOKEN}`
    }

    public get tokenType() {
        return `${environment.stage}_${TOKEN_TYPE}`
    }

    public saveUserSession(saveUserSession: boolean) {
        this._cookieService.set(SAVE_USER_SESSION, saveUserSession ? '1' : '0', this._EXP, this._PATH, this._DOMAIN, false);
    }

    public isUserSessionSaved(): boolean {
        return this._cookieService.get(SAVE_USER_SESSION) === '1';
    }

    public removeUserSession() {
        this._cookieService.delete(SAVE_USER_SESSION, this._PATH, this._DOMAIN, false);
    }

    public savedUserToken(): UserToken {
        return this.isUserSessionSaved() ? this.savedCookieUserToken() : this.savedSessionUserToken();
    }

    public saveUserToken(userToken: Token) {
        this.isUserSessionSaved() ? this.saveCookieToken(userToken) : this.saveSessionToken(userToken);
    }

    public removeUserToken() {
        this.isUserSessionSaved() ? this.removeCookieToken() : this.removeSessionToken();
    }

    public savedUser(): User {
        return this.savedSession(USER_STORAGE);
    }

    public saveUser(user: User) {
        this.saveSession(USER_STORAGE, user);
    }

    public removeUser() {
        this.removeSession(USER_STORAGE);
    }

    private saveCookieToken(userToken: Token) {
        this._cookieService.set(this.accessToken, userToken.access_token, this._EXP, this._PATH, this._DOMAIN, false);
        this._cookieService.set(this.refreshToken, userToken.refresh_token, this._EXP, this._PATH, this._DOMAIN, false);
        this._cookieService.set(this.tokenType, userToken.token_type, this._EXP, this._PATH, this._DOMAIN, false);
    }

    private saveSessionToken(userToken: Token) {
        this.saveSession(this.accessToken, userToken.access_token);
        this.saveSession(this.refreshToken, userToken.refresh_token);
        this.saveSession(this.tokenType, userToken.token_type);
    }

    private savedCookieUserToken(): UserToken {
        const accessToken = this._cookieService.get(this.accessToken);
        const refreshToken = this._cookieService.get(this.refreshToken);
        const tokenType = this._cookieService.get(this.tokenType);

        return { 'access_token': accessToken, 'refresh_token': refreshToken, 'token_type': tokenType };
    }

    private savedSessionUserToken(): UserToken {
        const accessToken = this.savedSession(this.accessToken);
        const refreshToken = this.savedSession(this.refreshToken);
        const tokenType = this.savedSession(this.tokenType);

        return { 'access_token': accessToken, 'refresh_token': refreshToken, 'token_type': tokenType };
    }

    private removeCookieToken() {
        this._cookieService.delete(this.accessToken, this._PATH, this._DOMAIN, false);
        this._cookieService.delete(this.refreshToken, this._PATH, this._DOMAIN, false);
        this._cookieService.delete(this.tokenType, this._PATH, this._DOMAIN, false);
    }

    private removeSessionToken() {
        this.removeSession(this.accessToken);
        this.removeSession(this.refreshToken);
        this.removeSession(this.tokenType);
    }
}
