// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headers {
  font-size: 12px;
}

.values {
  border-bottom: 1px solid grey;
}

ion-grid {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}
ion-grid ion-col {
  overflow: hidden;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/app/components/dimentions/dimentions.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AAEE;EACE,6BAAA;AACJ;;AAEE;EACE,aAAA;EACA,cAAA;EACA,eAAA;AACJ;AACI;EACE,gBAAA;EACA,mBAAA;AACN","sourcesContent":[".headers {\n    font-size: 12px;\n  }\n\n  .values{\n    border-bottom: 1px solid grey\n  }\n\n  ion-grid {\n    margin-top:0;\n    margin-left: 0;\n    margin-right: 0;\n\n    ion-col {\n      overflow: hidden;  \n      white-space: nowrap;\n    }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
