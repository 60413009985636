import { CoreService } from '@alphaa/services/core.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(protected _core: CoreService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(err => {
                if (err.status == 422) {
                    err.error.detail = err.error.detail.map(item => JSON.stringify(item)).join('\n');
                } else if (!err.ok) {
                    console.log('ERROR', JSON.stringify(err));
                }
                return throwError(err);
            }),
        );
    }
}
