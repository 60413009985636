// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `image-titled {
  display: contents;
}

.hero-banner {
  align-items: center;
  display: flex;
  position: relative;
  justify-content: center;
  z-index: 0;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .hero-banner {
    width: 90%;
  }
}

.hero-banner__title {
  color: #fff;
  font-weight: 700;
  padding: 0 20px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  font-family: helvetica;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .hero-banner__title {
    padding: 0;
  }
}

.hero-banner__stroked-title {
  -webkit-text-stroke: 2px white;
  text-stroke: 2px white;
  margin: 0 !important;
  color: transparent !important;
}

.hero-banner__image {
  border-radius: 5px;
  position: fixed;
  transform: rotate(-15deg);
  width: 30vw !important;
}
@media screen and (max-width: 767px) {
  .hero-banner__image {
    width: 60vw !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/imaged-titled/image-titled.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,mBAAA;EACA,aAAA;EACA,kBAAA;EACA,uBAAA;EACA,UAAA;EACA,WAAA;AACF;AACE;EARF;IASI,UAAA;EAEF;AACF;;AACA;EACE,WAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;EACA,sBAAA;EACA,WAAA;AAEF;AAAE;EAVF;IAWI,UAAA;EAGF;AACF;;AAAA;EACE,8BAAA;EACA,sBAAA;EACA,oBAAA;EAEA,6BAAA;AAEF;;AAEA;EACE,kBAAA;EACA,eAAA;EACA,yBAAA;EAEA,sBAAA;AAAF;AAEE;EAPF;IASI,sBAAA;EAAF;AACF","sourcesContent":["image-titled {\n  display:contents;\n}\n\n.hero-banner {\n  align-items: center;\n  display: flex;\n  position: relative;\n  justify-content: center;\n  z-index: 0;\n  width:100%;\n\n  @media screen and (max-width: 767px) {\n    width: 90%;\n  }\n}\n\n.hero-banner__title {\n  color: #fff;\n  font-weight: 700;\n  padding: 0 20px;\n  position: absolute;\n  text-align: center;\n  text-transform: uppercase;\n  font-family: helvetica;\n  width:100%;\n\n  @media screen and (max-width: 767px) {\n    padding: 0;\n  }\n}\n\n.hero-banner__stroked-title {\n  -webkit-text-stroke: 2px white;\n  text-stroke: 2px white;\n  margin:0 !important;\n  //font-size: 4em !important;\n  color:transparent !important;\n\n}\n\n.hero-banner__image {\n  border-radius: 5px;\n  position: fixed;\n  transform: rotate(-15deg);\n  //max-width: 30% !important;\n  width: 30vw !important;\n\n  @media screen and (max-width: 767px) {\n    //max-width: 70% !important;\n    width: 60vw !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
