import { ACCESS_TOKEN, CURRENT_USER, SUPER_USER, USER_STORAGE } from '@alphaa/constants/constants';
import { FJwtToken, JwtToken } from '@alphaa/models/auth/jwt-token';
import { DynClass } from '@alphaa/models/dyn.model';
import { User, UserToken } from '@alphaa/models/user.model';
import { CoreService } from '@alphaa/services/core.service';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { HttpHeaders } from '@angular/common/http';
import { environment } from '@envs/environment';
import _ from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ApiAnswer } from '../models/apiAnswer.model';
import { UserTokenService } from './token.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService extends DynClass {
    private currentUserSubject: BehaviorSubject<User>;
    private currentUserTokenSubject: BehaviorSubject<UserToken>;

    constructor(protected core: CoreService, private readonly _jwtHelper: JwtHelperService, private readonly _userTokenService: UserTokenService) {
        super();
    }

    public get currentUser(): User {
        let userToken = this.currentUserSubject?.value;
        if (!userToken) {
            this.currentUserSubject = new BehaviorSubject<User>(this._userTokenService.savedUser());
            userToken = this.currentUserSubject?.value;
        }
        return userToken;
    }

    public get currentUserToken(): UserToken {
        let userToken = this.currentUserTokenSubject?.value;
        if (!userToken) {
            this.currentUserTokenSubject = new BehaviorSubject<UserToken>(this._userTokenService.savedUserToken());
            userToken = this.currentUserTokenSubject?.value;
        }
        return userToken;
    }

    /**
     * Return token payload
     * @returns
     */
    getTokenPayload(): FJwtToken {
        const token = this.currentUser?.token;
        if (token) {
            return this._jwtHelper.decodeToken(token) as FJwtToken;
        }
        return null;
    }

    /**
     * Check if token is valid for this application ID
     * @returns
     */
    isAuthenticated(): boolean {
        if (environment.authMode == 'access_token') {
            return this._isAuthenticatedToken();
        }
        const token = this.currentUser?.token;
        if (token) {
            const isTokenExpired = this._jwtHelper.isTokenExpired(token);

            return !isTokenExpired;
        }
        return false;
    }

    /**
     * Check if token is valid for this application ID
     * @returns
     */
    private _isAuthenticatedToken(): boolean {
        const access_token = this.currentUserToken?.access_token;
        if (access_token) {
            const isTokenExpired = this._jwtHelper.isTokenExpired(access_token);
            if (isTokenExpired) {
                console.log('Token expired');
            }
            return !isTokenExpired;
        }
        return false;
    }

    /**
     * Check if one of permissions needed are present in user permissions
     * @param permissions
     * @returns
     */
    hasAuthorizedPermissions(permissions: string[], admin_permissions: string[] = [], is_admin_access: boolean = true): boolean {
        admin_permissions.push(SUPER_USER);
        const userPermissions = this.getPermissions();
        const admin: boolean = _.intersection(userPermissions, admin_permissions).length > 0;
        const hasRole: boolean = _.intersection(permissions, userPermissions).length > 0;
        if (hasRole || (is_admin_access && admin)) {
            return true;
        }
        return false;
    }

    /**
     * Return user permissions present in the JWT token, else return an empty list
     * @returns
     */
    public getPermissions(): string[] {
        if (environment.authMode == 'access_token') {
            return this.currentUser ? this.currentUser.permissions : [];
        }
        const token = this.currentUser?.token;
        if (token) {
            const tokenPayload = this._jwtHelper.decodeToken(token) as JwtToken;
            return tokenPayload.permissions ? tokenPayload.permissions : [];
        }
        return [];
    }

    login(username: string, password: string, saveSession: boolean = false): Observable<ApiAnswer> {
        if (environment.authMode == 'access_token') {
            return this._loginToken(username, password, saveSession);
        }
        return this.core.http.post('auth', { username, password }).pipe(
            map(answer => {
                // login successful if there's a jwt token in the response
                if (answer.valid && answer.data.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    const user = answer.data;
                    if (user) {
                        if (this._userTokenService.isUserSessionSaved()) {
                            this.save(USER_STORAGE, user);
                            console.log('Logged using storage');
                        } else {
                            this.saveSession(USER_STORAGE, user);
                            console.log('Logged using session');
                        }
                    }
                    this.currentUserSubject.next(user);
                    this.core.router.navigate(['/home']);
                }
                return answer;
            }),
        );
    }

    private _loginToken(username: string, password: string, saveSession: boolean): Observable<ApiAnswer> {
        this._userTokenService.saveUserSession(saveSession);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
            observe: 'response' as 'response',
        };

        const body = new URLSearchParams();
        body.set('username', username);
        body.set('password', password);

        return this.core.http.post(`${environment.authUrl}auth/login`, body.toString(), undefined, httpOptions).pipe(
            switchMap(response => {
                if (response.status == 200) {
                    return this.getUserMe(response.data as UserToken);
                }
                return of(response);
            }),
        );
    }

    public getUserMe(userToken: UserToken) {
        this._userTokenService.saveUserToken(userToken);

        this.currentUserTokenSubject.next(userToken);

        return this.core.http.post(`${environment.authUrl}users/me`, undefined).pipe(
            switchMap(response => {
                if (response.status === 200) {
                    const user = response.data;

                    if (user) {
                        this._userTokenService.saveUser(user);
                    }

                    this.currentUserSubject.next(user);
                }
                return of(response);
            }),
        );
    }

    cleanCurrentUserTokenSubject() {
        this.currentUserTokenSubject.next(null);
    }

    logout() {
        if (environment.authMode == 'access_token') {
            return this._logoutToken();
        }
        return this.core.http.post('logout', {}).subscribe({
            next: _ => {
                console.log('LOGOUT');
                this._userTokenService.isUserSessionSaved() ? this.remove(USER_STORAGE) : this.removeSession(USER_STORAGE);
                this._userTokenService.isUserSessionSaved() ? this.remove(this._userTokenService.accessToken) : this.removeSession(this._userTokenService.accessToken);
                this._userTokenService.isUserSessionSaved() ? this.remove(CURRENT_USER) : this.removeSession(CURRENT_USER);
                this.currentUserSubject.next(null);
                this.core.router.navigate(['/login']);
            },
            error: err => {
                console.log(err);
            },
        });
    }

    private _logoutToken() {
        this._userTokenService.removeUserToken();
        this._userTokenService.removeUser();
        this._userTokenService.removeUserSession();

        this.cleanCurrentUserTokenSubject();
        this.core.router.navigate(['/login']);
    }

    refreshToken(): Observable<ApiAnswer> {
        this.cleanCurrentUserTokenSubject();

        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': `${this.currentUserToken?.token_type} ${this.currentUserToken?.refresh_token}`,
            }),
            observe: 'response' as 'response',
        };

        return this.core.http.post(`${environment.authUrl}auth/refresh`, undefined, undefined, httpOptions, true).pipe(
            switchMap(response1 => {
                if (response1.valid) {
                    const userToken = response1.data as UserToken;

                    if (userToken) {
                        this._userTokenService.saveUserToken(userToken);
                    }

                    this.currentUserTokenSubject.next(userToken);
                } else {
                    this.logout();
                }
                return of(response1);
            }),
        );
    }

    register(user: User): Observable<ApiAnswer> {
        return this.core.http.post('register', user);
    }

    resetPassword(username: string): Observable<ApiAnswer> {
        return this.core.http.post('password/lost', { username });
    }

    sendNewPassword(password: string, passwordConfirmation: string, tmpToken: string): Observable<ApiAnswer> {
        return this.core.http.post('password/reset', {
            password,
            password_confirmation: passwordConfirmation,
            tmp_token: tmpToken,
        });
    }

    validateRegistration(tmpToken: string): Observable<ApiAnswer> {
        return this.core.http.post('register/validation', { tmp_token: tmpToken });
    }

    isCurrentUser(id: number) {
        return this.currentUser?.id === id;
    }

    public isUserSessionSaved(): boolean {
        return this._userTokenService.isUserSessionSaved();
    }
}
