import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";



@Component({
  selector: 'rating',
  templateUrl: './rating.html',
  styleUrls: ['./rating.scss'],
})
export class RatingComponent implements OnInit {
    @Input() value:number = 5;

    rating: boolean = false;

    
    constructor() {}

    ngOnInit(): void {}

    get title(): string {
        return this.value + ' / 5';
    }

    calculatePercent(value: number): string {
        return `${(value / 5) * 100}%`;
    }
}