import { ICoreService } from '@alphaa/services/ionic/icore.service';

import { Component, EventEmitter, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NEWSLETTER_LIST } from '@constants/brevo';
import { environment } from '@envs/environment';
import { AlertController, CheckboxCustomEvent } from '@ionic/angular';
import { ADNView } from '@models/view';
import { ApiService } from '@services/api.service';
import { EmailService } from '@services/mail.service';

import { UtilService } from '@services/util.service';
import { catchError, of, pipe, switchMap, throwError } from 'rxjs';

@Component({
    selector: 'adn-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    encapsulation: ViewEncapsulation.None
  })
export class FooterComponent extends ADNView {
  public cookies:boolean = true;
  public analytics: boolean = true;
  public marketing: boolean = true;

  canDismiss = false;

  presentingElement = null;
  newsletter: string = '';
  message:string = '' ;

  @Output() goToParent = new EventEmitter<void>();

  public contactForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    acceptNews: [false, Validators.requiredTrue],
    recaptcha: ['', false && environment.production ? [Validators.required]: []]
  });

  constructor(
    protected core: ICoreService, 
    public util: UtilService, 
    public alertController: AlertController,
    protected route: ActivatedRoute, protected router: Router,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private emailService: EmailService
  ) { 
    super(core, route, router, util);
    this.cookies = this.saved("cookies", true);
  }
  
  submitForm() {
    this.core.showSpinner();
    this.newsletter = 'send';
    this.api.saveEmailNewsletter(this.contactForm.get('email').value).subscribe(
      response => { // Succès
        this.message = "NEWSLETTER.VALID";
        this.contactForm.reset();
      },
      error => { // Échec
        console.error('Erreur lors de l\'enregistrement du mail', error);
        this.message = error.status == 409 ? "NEWSLETTER.ALREADY": "NEWSLETTER.ERROR";
        this.core.hideSpinner();
      },
      () => { // Achèvement
        this.core.hideSpinner();
      }
    );

    const email = this.contactForm.get('email').value;
    this.emailService.addContact(email, [NEWSLETTER_LIST], {})
    .pipe(
      switchMap(response => {
        // Si le premier appel réussit, on peut immédiatement retourner une réponse sans faire le deuxième appel
        return of(response);
      }),
      catchError((error) => {
        // Si le premier appel échoue pour une raison autre que 'duplicate_parameter', re-lancer l'erreur
        if (!(error.status === 400 && (error.error.code === 'duplicate_parameter' || error.error.message.indexOf('already') !== -1))) {
          return throwError(() => error);
        }
        // Si on est ici, cela signifie que l'erreur est due à un 'duplicate_parameter', donc on tente le deuxième appel
        return this.emailService.addContactList(email, NEWSLETTER_LIST).pipe(
          catchError((error) => {
            // Si l'erreur est à nouveau une erreur 400 avec 'duplicate_parameter', on la gère
            if (error.status === 400 && (error.error.code === 'duplicate_parameter' || error.error.message.indexOf('already') !== -1)) {
              console.log('Le contact existe déjà. Continuation du processus.');
              return of(null);
            } else {
              // Pour toutes les autres erreurs, les re-lancer
              return throwError(() => error);
            }
          })
        );
      })
    )
    .subscribe({
      next: (response) => console.log('Succès', response),
      error: (error) => console.error('Erreur', error)
    });
  }

  getLink(sm) {
    return sm.stitle ? sm.stitle : sm.title;
  }

  currentYearLong(): number {
    return new Date().getFullYear();
    }

  onTermsChanged(event: Event) {
    const ev = event as CheckboxCustomEvent;
    this.canDismiss = ev.detail.checked;
  }

  closeCookies() {
    this.cookies=false;
    this.save("cookies", false);
  }

  goTo(url: string, parameters: {}) {
    this.goToParent.emit();
    this.core.goTo(url, parameters);
  }
}
