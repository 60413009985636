
import { Injectable } from '@angular/core';
import { LoadingController, AlertController, ToastController, NavController, MenuController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { DynClass } from '@alphaa/models/dyn.model';
import { PRODUCT_VERSION, Ski, SKIS } from '@models/skis';
import { PARAMETERS } from '@constants/parameters';
import { MenuItem, menuLinks } from '@constants/menu';
import { CoreService } from '@alphaa/services/core.service';
import { environment } from '@envs/environment';
import { TranslateService } from "@ngx-translate/core";
import { IClientAuthorizeCallbackData, ICreateOrderRequest, IOnApproveCallbackData, IPayPalConfig } from 'ngx-paypal';
import { ICoreService } from '@alphaa/services/ionic/icore.service';

function getNextWednesday19(): Date {
  const now = new Date();
  const result = new Date(now);
  result.setDate(now.getDate() + ((3 + 7 - now.getDay()) % 7)); // 3 représente mercredi
  result.setHours(19, 0, 0, 0); // Réglez l'heure à 19h00

  // Si maintenant est après 19h ce mercredi, prenez le mercredi suivant
  if (now > result) {
      result.setDate(result.getDate() + 7);
  }

  return result;
}

function getCountdown() {
  let targetDate: Date = getNextWednesday19();
  const now = new Date();
  const difference = targetDate.getTime() - now.getTime();

  if (difference <= 0) {
      return 0;
  }

  // Convertir la différence en minutes
  const minutes = Math.floor(difference / (1000 * 60));

  return minutes;
}


interface Cart {
  ski: Ski;
  size: number;
  nb:number;
}

@Injectable({
  providedIn: 'root'
})
export class UtilService extends DynClass {
  olangage: string = 'en';

  languages = [{code:'fr', default:true}, {code:'en'}];

  activeSlide: number = 0;

  loader: any;
  isLoading = false;

  menuLinks: MenuItem[] = menuLinks;
  menuRows = [0,1,2,3];

  visibleLeftMenu:boolean = false;
  
  mh:boolean = false;

  hideMenu : boolean = false;
  isTop:boolean = true;

  skis = SKIS;
  public parameters = PARAMETERS;

  timeoutId:any;

  page:string;
  whiteTitle : boolean = false;
  transp: boolean = false;

  videoSrc:string;

  public language: string = environment.language;

  public cart : Cart[] = [];

  promoStr:string;
  private promos = [
    {key:'CE ST CROLLES', value:10},
    {key:'CE_ST_CROLLES', value:10},
    {key:'CE ST GRENOBLE', value:10},
    {key:'CE_ST_GRENOBLE', value:10},
    {key:'VP40', value:40},
    {key: 'S150', value:0}
  ];


  orderConfirmation: IOnApproveCallbackData;
  orderResponse: IClientAuthorizeCallbackData;
  public payPalConfig?: IPayPalConfig;
  public cartMode: string = 'cart';

  public countDown: number = 0;
  public countDownH:number = 0;
  public countDownM:number = 0;
  
  pages = {
    techno: {
      category: "TECHNO",
      groups: [
        {img:'1.png', content:"ECO"},
        {img:'2.png', content:"MATERIALS"},
        {img:'3.png', content:"PERFORMANCES"},
        {img:'4.png', content:"RECYCLING"},
      ]
    },
    ecodesign: {
      category: "TECHNO",
      groups: [
        {img:'main/bois.jpg', content:"ECO", index:1},
        {img:'1.png', content:"ECO", index:2},
        {img:'ecodesign/373458356_838882161238155_8989466862499752625_n.jpg', content:"ECO", index:3},
      ]
    },
    materials: {
      category: "TECHNO",
      groups: [
        {img:'2.png', content:"MATERIALS", index:1},
        {img:'materials/resin.jpg', content:"MATERIALS", index:2},
        {img:'materials/materiaux.jpg', content:"MATERIALS", index:3},
      ],
      style: 'vertical'
    },
    recycling: {
      category: "TECHNO",
      groups: [
        {img:'recycling/demontage.png', content:"RECYCLING", index:1},
        {img:'recycling/recy.jpg', content:"RECYCLING", index:2},
        {img:'3.png', content:"RECYCLING", index:3},
      ]
    },
    ingredientbrand: {
      category: "TECHNO",
      groups: [
        {img:'main/salomon.png', content:"INGREDIENT_BRAND", index:1},
        {img:'main/techno.jpg', content:"INGREDIENT_BRAND", index:2},
        {img:'main/ingredients.jpg', content:"INGREDIENT_BRAND", index:3},
      ]
    },
    maintenance: {
      category: "MAINTENANCE",
      groups: [
        {img:'359306058_812431663919839_4708273275266383104_n.jpg', content:"CLEANING"},
        {img:'IMG-20231220-WA0003.jpg', content:"WAXING"},
        {img:'sharpening.jpg', content:"SHARPENING"},
        {img:'358337896_107233719129933_1014280007319247812_n.jpg', content:"REPAIR"},
        {img:'stock.jpg', content:"STORAGE"},
      ]
    }
  }

  promoSing;
  
  constructor(
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    private toastCtrl: ToastController,
    public router: Router,
    private route: ActivatedRoute,
    private core: ICoreService,
    public translate: TranslateService
  ) {
    super();
    translate.setDefaultLang(this.language);
    //translate.use(this.language);
    this.visibleLeftMenu = this.saved("visibleLeftMenu",false);

    this.cart = JSON.parse(localStorage.getItem("cart") || "[]").map(s=> new Ski().assign(s));
    this.cart = this.cart.filter(c=> c.ski?.version == PRODUCT_VERSION);

    this.countDown = getCountdown();
    this.countDownH = Math.floor(this.countDown/60);
    this.countDownM = this.countDown%60;
    setInterval(() => {
      this.countDown = getCountdown();
      this.countDownH = Math.floor(this.countDown/60);
      this.countDownM = this.countDown%60;
    }, 60000);
  }

  get promo () {
    return this.promos.find(p => p.key==this.promoStr?.toUpperCase());
  }

  get isPromo () {
    return this.promo != undefined;
  }

  getPromo() {
    return this.promo ? Math.round(this.getTotal(false) / this.promo.value) : 0;
  }

  get isValidKey() {
    const vpKey = localStorage.getItem('vpKey');
    const vpKeyEnabled = environment.vpKey;
    return vpKey == vpKeyEnabled;
  }

  promoChange() {
    const vpKey = localStorage.getItem('vpKey');
    const vpKeyEnabled = environment.vpKey;
    if (vpKey != vpKeyEnabled && this.promoStr == 'VP40') {
      this.promoStr = '';
    } else if (vpKey == vpKeyEnabled) {
      this.promoStr = 'VP40';
    }
  }

  addToCart(ski:Ski, selectedSize:number) {
    let inCard = this.cart.find(c=>c.ski.uuid==ski.uuid && c.size==selectedSize)
    if (!inCard) this.cart.push({ski:ski, size:selectedSize,nb:1});
    else inCard.nb += 1;

    this.saveCart();
  }

  removeFromCart(cart:Cart) {
    this.cart = this.cart.filter(c=>c!=cart);
    this.saveCart();
  }

  saveCart() {
    localStorage.setItem('cart', JSON.stringify(this.cart));
  }

  resetCart() {
    this.cart = [];
    localStorage.setItem('cart', "[]");
  }

  changeQty(cart, up:boolean) {
    if (!up && cart.nb!=0) cart.nb = cart.nb - 1;
    else if (up && cart.nb<10) cart.nb = cart.nb + 1;
    if (cart.nb == 0) this.cart = this.cart.filter(c=>c.nb!=0)
    localStorage.setItem('cart', JSON.stringify(this.cart));
  }

  get cartEnabled() {
    return this.cart.length !== 0 && this.cart.filter(e=>e.nb != 0).length !== 0;
  }

  async initLanguage() {
    let language = this.translate.getBrowserLang();

    language =
      language && ["en", "fr"].indexOf(language) !== -1 ? language : this.language;
    const storedlanguage = localStorage.getItem("lang");
    this.language = storedlanguage ?? language;
    console.log("set lang init", this.language);
    this.translate.setDefaultLang(this.language);
    this.translate.use(this.language);
  }

  isSki() {
    return this.cart.filter(c=>c.ski.category=='skis').length != 0;
  }

  selectLanguage(lang: string) {
    this.language = lang;
    localStorage.setItem("lang", lang);
    this.translate.use(lang);
    console.log('set lang', lang)
    //window.location.reload();
  }

  switchLangage() {
    const language = this.languages.filter(l=>l.code != this.language)[0].code;
    this.olangage = this.languages.find(l => l.code != this.language).code;
    this.selectLanguage(language);
  }

  processContext() {
    let url = this.router.url;

    this.route.queryParams.subscribe(params => {
      params.index
        let group = this.menuLinks.find(m => url.indexOf(m.link) != -1);

        let sb = params.index && group ? group?.subs?.find(s => params.index == s.params.index) : undefined;
        if (url.indexOf("contact") !== -1) {
          group = this.menuLinks.find(m => url.indexOf(m.link) != -1 && m?.subs?.find(s=>s.params?.page===params.page));
          sb = sb ?? group?.subs?.find(s => params.page == s.params?.page);
          this.page = group ? group.title : ''; // sb.title;
        } else {
          if (!sb) {
            this.page = group ? group.title : '';
            this.whiteTitle = false;
            this.transp = false;
          } else {
            const sub_title = this.activeSlide == 0 && !sb.noGroupTitle;
            this.page = sub_title ? (group ? group.title : '') : (sb.stitle ? sb.stitle :sb.title);
            this.whiteTitle = sb.white !== undefined && sb.white.indexOf(this.activeSlide) !== -1;
            this.transp = sb.transp !== undefined && sb.transp.indexOf(this.activeSlide) !== -1
          }
        }
    });
  }

  isMenuVisible() {
    return this.mh
  }

  showHideMenu(over:boolean) {
    this.mh = over;
  }


  getMenu(index:number) {
    return this.menuLinks.filter(m=>m.row == index);
  }

  playPause(videoSrc, disable?:boolean) {
    if (disable) return;
    this.videoSrc = videoSrc;
    this.hideMenu = true;
    const video = document.getElementById('videoP') as HTMLVideoElement;
    if (!video) return;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  }

  closeVideo() {
    var myVideo: any = document.getElementById('videoP') as HTMLVideoElement;
    if (myVideo && !myVideo.paused) {
      myVideo.pause();
    }
    this.hideMenu = false;
    this.videoSrc = '';
  }

  openMenu() {
    this.save("visibleLeftMenu",!this.visibleLeftMenu);

    //this.menuController.open();
    const splitPane = document.querySelector('ion-split-pane');
    const windowWidth = window.innerWidth;
    const splitPaneShownAt = 992;
    const when = `(min-width: ${splitPaneShownAt}px)`;

    const menu = splitPane.querySelector('ion-menu');

    if (windowWidth >= splitPaneShownAt) {
      // split pane view is visible
      const open = splitPane.when === when;
      splitPane.when = open ? false : when;
    } else {
      // split pane view is not visible
      // toggle menu open

      return menu.open();
    }
  }


  /*
  Start Loader
  Call this method to Start your Loader
  */
  async show() {
    this.isLoading = true;
    return await this.loadingCtrl.create({
      // duration: 5000,
      spinner: 'dots',
    }).then(a => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }
  success_msg(title) {
    Swal.fire({
      icon: 'success',
      title: title,
      showConfirmButton: false,
      timer: 1500
    });
  }

  async hide() {
    this.isLoading = false;
    return await this.loadingCtrl.dismiss().then(() => console.log('dismissed'));
  }

  /*
    Show Warning Alert Message
    param : msg = message to display
    Call this method to show Warning Alert,
    */
  async showWarningAlert(msg) {
    const alert = await this.alertCtrl.create({
      header: 'Warning',
      message: msg,
      buttons: ['ok']
    });
    await alert.present();
  }
  async showSimpleAlert(msg) {
    const alert = await this.alertCtrl.create({
      header: 'warning',
      message: msg,
      buttons: ['ok']
    });
    await alert.present();
  }
  /*
   Show Error Alert Message
   param : msg = message to display
   Call this method to show Error Alert,
   */
  async showErrorAlert(msg) {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message: msg,
      buttons: ['ok']
    });
    await alert.present();
  }
  /*
     param : email = email to verify
     Call this method to get verify email
     */
  async getEmailFilter(email) {
    const emailfilter = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
    if (!(emailfilter.test(email))) {
      const alert = await this.alertCtrl.create({
        header: 'warning',
        message: 'Please enter valid email',
        buttons: ['ok']
      });
      await alert.present();
      return false;
    } else {
      return true;
    }
  }
  /*
    Show Toast Message on Screen
     param : msg = message to display, color= background
      color of toast example dark,danger,light. position  = position of message example top,bottom
     Call this method to show toast message
     */
  async showToast(msg, color, positon) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2000,
      color: color,
      position: positon
    });
    toast.present();
  }
  async errorToast(msg, color?) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 2000,
      color: color ? 'dark' : 'light'
    });
    toast.present();
  }

  apiErrorHandler(err) {
    console.log('error', err);
  }

  getDiscount() {
    let totalPromo = 0;
    this.promoSing = false;
    this.cart.forEach(c => {
        totalPromo += this.getDicsountProduct(c);
    });
    this.promoSing = false;

    return this.promo ? totalPromo : 0;
  }

  getDiscountProduct(ski) {
    return Math.round((ski.price / 100)* this.promo.value);
  }

  getDicsountProduct(cart) {
    let promo = 0;
    if (this.promo?.value && (cart.ski.category == 'skis' || (this.promoStr != 'VP40'))) {
      promo += Math.round((cart.nb*cart.ski.price / 100)* this.promo.value);
    }

    if (!this.promoSing && this.promoStr == 'S150' && cart.ski.category == 'skis') {
      promo = Math.round((1 - 150 / cart.ski.price) * cart.ski.price);
      this.promoSing = true;
    }

    return promo;
  }

  getTotal(delivery:boolean=true, nopromo:boolean=false) {
    let total = 0;
    this.cart.forEach((c,i) => {
      var price = c.ski.price;
      total += c.nb*price;
    });
    if (this.promo && !nopromo) total = total - this.getDiscount();

    if (delivery) {
      total += this.getDelivery();
    }
    return total;
  }

  getDelivery() {
    let deliveryNb = 0;
    this.cart.forEach(c => {
     deliveryNb += c.ski.delivery * c.nb;
    });
    return deliveryNb;
  }

  getTotalNb() {
    let nb = 0;
    this.cart.forEach(c => {
      nb += c.nb;
    });
    return nb;
  }

  errorPromo() {
    return this.promoStr=='QVEMA5' && this.cart.filter(c=>c.ski.category=='skis').length == 0;
  }

  public buildPurchaseUnits() {
    let data;
    // Logique pour construire purchase_units basée sur l'état actuel du panier
    const items = this.cart.map(cart => {
      let name = [cart.ski.name, cart.size, cart.ski.color].filter(e=>e).join(' - ');
      console.log('Purchase unit ',name)
      let price = cart.ski.price;

      return {
        name: name,
        quantity: cart.nb.toString(), 
        category: 'DIGITAL_GOODS', 
        unit_amount: {
          currency_code: 'EUR',
          value: price.toString(),
        },
      };
    });

    const total = this.getTotal(true);
    const discount = this.getDiscount();

    if (this.promo) {
      data = [
        {
          amount: {
            currency_code: 'EUR',
            value: total.toString(),
            breakdown: {
              item_total: {
                currency_code: 'EUR',
                value: this.getTotal(false, true).toString()
              },
              shipping: {
                currency_code: 'EUR',
                value:(this.getDelivery()).toString()
              },
              discount: { 
                currency_code: 'EUR',
                value: discount.toString()
              }
            }
          },
          items: items
        }
      ];
    } else {
      data = [
        {
          amount: {
            currency_code: 'EUR',
            value: this.getTotal(true).toString(),
            breakdown: {
              item_total: {
                currency_code: 'EUR',
                value: this.getTotal(false).toString()
              },
              shipping: {
                currency_code: 'EUR',
                value:(this.getDelivery()).toString()
              }
            }
          },
          items: items
        }
      ];
    }
    if(!environment.production) {
      console.log('data',data);
    }
    return data;
  }

}
