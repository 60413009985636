import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { environment } from '@envs/environment';
import { ICoreService } from '../../alphaa/services/ionic/icore.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private url = environment.apiUrl; 
  private token: string | null = null;

  constructor(private http: HttpClient, private core: ICoreService) {
    this.token = localStorage.getItem('adminToken');
  }

  getInstas() {
    return this.core.http.get(`${this.url}/insta`, {}, true);
  }

  getTempToken() {
    return this.http.get(`${this.url}/temp_token`);
  }
  
  isToken() {
    return this.token != null && this.token !== undefined && this.token != ''; 
  }

  setToken(token: string): void {
    this.token = token;
    localStorage.setItem('adminToken', token);
  }

  getContacts(): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.token || '');
    return this.http.get(`${this.url}/contacts`, { headers });
  }

  getIdeas(): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.token || '');
    return this.http.get(`${this.url}/ideas`, { headers });
  }

  getNewsletter(): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.token || '');
    return this.http.get(`${this.url}/newsletter`, { headers });
  }

  generateToken(key: string): Observable<any> {
    return this.http.post(`${this.url}/generate_token`, { key });
  }

  getTokenValidity(): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', this.token || '');
    return this.http.get(`${this.url}/check_token`, { headers });
  }

  getIPAddress() {
    return this.http.get<{ip: string}>('https://api.ipify.org?format=json');
  }

  saveEmailNewsletter(email:string) {
    return this.getTempToken().pipe(
      switchMap((tokenResponse: any) => {
        const headers = new HttpHeaders({
          Authorization: tokenResponse.token // Assurez-vous que la structure de la réponse correspond
        });

        return this.http.post(`${this.url}/newsletter`, {email:email,etype:'news'}, { headers });
      })
    );
  }

  saveReview(product:string, firstname:string, name:string, email:string, title:string, review:number, message:string) {
    return this.getTempToken().pipe(
      switchMap((tokenResponse: any) => {
        const headers = new HttpHeaders({
          Authorization: tokenResponse.token // Assurez-vous que la structure de la réponse correspond
        });

        return this.http.post(`${this.url}/product/test`, {product:product, firstname:firstname, name:name, title:title, message:message,review:review, email:email}, { headers });
      })
    );
  }

  saveEmailVp(email:string) {
    return this.getTempToken().pipe(
      switchMap((tokenResponse: any) => {
        const headers = new HttpHeaders({
          Authorization: tokenResponse.token // Assurez-vous que la structure de la réponse correspond
        });

        return this.http.post(`${this.url}/newsletter`, {email:email,etype:'vp'}, { headers });
      })
    );
  }

  saveIdea(message: string) {
    return this.getTempToken().pipe(
      switchMap((tokenResponse: any) => {
        const headers = new HttpHeaders({
          Authorization: tokenResponse.token // Assurez-vous que la structure de la réponse correspond
        });

        return this.http.post(`${this.url}/idea`, {message:message}, { headers });
      })
    );
  }

  sendMessage(subject:string, name:string, title:string, email:string, message: string) {
    return this.getTempToken().pipe(
      switchMap((tokenResponse: any) => {
        const headers = new HttpHeaders({
          Authorization: tokenResponse.token // Assurez-vous que la structure de la réponse correspond
        });

        return this.http.post(`${this.url}/contact`, {subject:subject,name:name,title:title,email:email,message:message}, { headers });
      })
    );
  }

  getContestLink(name: string, email: string, parrain:string, news:boolean) {
    const browserInfo = {
      appName: navigator.appName,
      appVersion: navigator.appVersion,
      userAgent: navigator.userAgent,
      platform: navigator.platform
    };
    console.log('Informations sur le navigateur:', browserInfo);

    let ipAddress: string;

    return this.getIPAddress().pipe(
      switchMap((ipResponse: any) => {
        ipAddress = ipResponse.ip;
        return this.getTempToken();
      }),
      switchMap((tokenResponse: any) => {
        const headers = new HttpHeaders({
          Authorization: tokenResponse.token // Assurez-vous que la structure de la réponse correspond
        });
        return this.http.post(`${this.url}/generate_contest_link`, { name, email, ip: ipAddress, parrain: parrain, news:news }, { headers });
      })
    );
  }

  incrementCounter(uuid) {
    const browserInfo = {
      appName: navigator.appName,
      appVersion: navigator.appVersion,
      userAgent: navigator.userAgent,
      platform: navigator.platform
    };
    console.log('Informations sur le navigateur:', browserInfo);

    let ipAddress: string;

    return this.getIPAddress().pipe(
      switchMap((ipResponse: any) => {
        ipAddress = ipResponse.ip;
        return this.getTempToken();
      }),
      switchMap((tokenResponse: any) => {
        const headers = new HttpHeaders({
          Authorization: tokenResponse.token // Assurez-vous que la structure de la réponse correspond
        });
        return this.http.post(`${this.url}/increment_counter`, { uuid:uuid, ip:ipAddress}, { headers });
      })
    );
  }

  productsRequest(uuid:string, size:number, email:string) {
    return this.getTempToken().pipe(
      switchMap((tokenResponse: any) => {
        const headers = new HttpHeaders({
          Authorization: tokenResponse.token // Assurez-vous que la structure de la réponse correspond
        });

        return this.http.post(`${this.url}/products/request`, {uuid:uuid, size:size, email:email}, { headers });
      })
    );
  }

  
  productsPurchase(items) {
    return this.getTempToken().pipe(
      switchMap((tokenResponse: any) => {
        const headers = new HttpHeaders({
          Authorization: tokenResponse.token // Assurez-vous que la structure de la réponse correspond
        });

        return this.http.post(`${this.url}/products/purchase`, items, { headers });
      })
    );
  }

  getCounter(uuid) {
    return this.core.http.get(`${this.url}/get_counter`, {uuid:uuid});
  }
}
