

import { AfterViewInit, Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'image-titled',
    templateUrl: './image-titled.component.html',
    styleUrls: ['./image-titled.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ImageTitledComponent implements OnInit  {
    @Input() img: string = "";
    @Input() title: string = "";
    @Input() centered: boolean = false;
    @Input() mheight: string = '50vh';

    constructor(

    ) {

    }

    ngOnInit() {}


    ionViewDidEnter() {
        
    }

    
}
